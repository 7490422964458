// React
import React from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';

const EmptyStateComponent = ({ label, theme }) => {
    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            className={cc([
                'flex flex-col items-center justify-center p-16 space-y-16  rounded-8',
                {
                    'bg-teal-20': isTeal,
                    'bg-blue-20': isBlue,
                },
            ])}>
            <p
                className={cc([
                    't-sh5',
                    {
                        'text-teal-100': isTeal,
                        'text-blue-100': isBlue,
                    },
                ])}>
                {label}
            </p>
        </div>
    );
};

EmptyStateComponent.propTypes = {
    text: t.string,
    theme: t.string,
};

EmptyStateComponent.defaultProps = {
    text: '',
    theme: 'teal',
};

export default EmptyStateComponent;
