// React
import React, { useEffect, useState, useRef } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useFormContext, useController } from 'react-hook-form';

// Utilities
import { useLabels, useModalState } from 'utilities/hooks';

// Components
import EmptyState from '../emptyState';
import SelectLongListModal from 'components/_modals/selectLongListModal';
import Button from 'components/button';

// Icons
import { FiX, FiExternalLink } from 'react-icons/fi';

const SelectLongListComponent = ({
    className,
    controller,
    defaultValue,
    missingOptionsLabel,
    name,
    object,
    options,
    placeholder,
    setValue,
    sort,
    theme,
    unregisterInput,
    disabled,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // HOOKS
    // ///////////////////

    const {
        modalState,
        modalOpen,
        modalClose,
        modalSaving,
        modalNotSaving,
    } = useModalState();

    const { label } = useLabels();
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
        defaultValue,
        rules: { required },
    });

    const formContext = useFormContext();

    // ///////////////////
    // STATE
    // ///////////////////

    const [asyncOptionsFetched, setAsyncOptionsFetched] = useState(false);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadedOptions, setLoadedOptions] = useState([]);
    const [query, setQuery] = useState('');
    const [active, setActive] = useState(false);

    // ///////////////////
    // METHODS
    // ///////////////////

    function getPlaceholder() {
        if (loadingOptions) {
            return label('FormCaptureSelectLoadingOptions');
        } else {
            if (loadedOptions.length > 0) {
                return placeholder || label('FormCaptureSelectEmpty');
            } else {
                return label('FormCaptureSelectNoOptions');
            }
        }
    }

    // ///////////////////
    // DATA
    // ///////////////////

    const missingOptions = missingOptionsLabel && loadedOptions.length === 0;

    const realOptions = loadedOptions
        .sort((a, b) => (sort ? a?.label?.localeCompare(b?.label) : true))
        .filter(option => !option.disabledInSelect)
        .filter(option =>
            query === ''
                ? true
                : option.label
                      .toLowerCase()
                      .replace(/\s+/g, '')
                      .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

    const realValue =
        value || defaultValue
            ? loadedOptions.find(option => option.value === value) ||
              loadedOptions.find(option => option.value === defaultValue)
            : null;

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        // Assume normal options
        if (Array.isArray(options)) {
            setLoadedOptions(options);
        }
        // Or perhaps async options
        else {
            async function getOptions() {
                setLoadingOptions(true);
                setLoadedOptions(await options());
                setLoadingOptions(false);
                setAsyncOptionsFetched(true);
            }
            if (!asyncOptionsFetched) {
                getOptions();
            }
        }
    }, [options]);

    // Defaultvalue
    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
        }
    }, [defaultValue, loadedOptions]);

    // Update value correctly when using setValue
    useEffect(() => {
        if (value && value.length > 0) {
            setValue(name, value);
        }
    }, [loadedOptions]);

    useEffect(() => {
        if (formContext && unregisterInput) {
            formContext.unregister(name);
        }
    }, [unregisterInput]);

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isBlueAlt = theme === 'blue-alt';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return !unregisterInput ? (
        <>
            <label className="flex flex-col grow">
                {inputLabel && (
                    <span
                        className={cc([
                            'input-label',
                            {
                                'input-label-blue': isBlue,
                                'input-label-blue-alt': isBlueAlt,
                                'input-label-teal': isTeal,
                            },
                        ])}>
                        {inputLabel}
                        {required && (
                            <span>({label('FormCaptureRequired')})</span>
                        )}
                    </span>
                )}
                {subLabel && (
                    <span
                        className={cc([
                            'mt-8 input-sublabel',
                            {
                                'input-sublabel-blue': isBlue,
                                'input-sublabel-blue-alt': isBlueAlt,
                                'input-sublabel-teal': isTeal,
                            },
                        ])}>
                        {subLabel}
                    </span>
                )}
                {missingOptions && (
                    <div className="mt-16">
                        <EmptyState label={missingOptionsLabel} theme={theme} />
                    </div>
                )}

                {!missingOptions && (
                    <div
                        className={cc([
                            'flex flex-col',
                            {
                                'mt-8': inputLabel || subLabel,
                            },
                        ])}>
                        <div className="flex items-end">
                            <input
                                type="text"
                                disabled={true}
                                value={realValue ? realValue.label : ''}
                                placeholder={getPlaceholder()}
                                className={cc([
                                    'input-defaults w-full pointer-events-none select-none',
                                    {
                                        'input-defaults-blue': isBlue,
                                        'input-defaults-blue-alt': isBlueAlt,
                                        'input-defaults-teal': isTeal,
                                        'input-defaults-error': error,
                                        'mt-16': inputLabel,
                                    },
                                ])}
                            />
                            <div
                                className="flex items-center justify-center w-32 h-40 ml-8 mr-4 cursor-pointer"
                                onClick={() => {
                                    if (realValue) {
                                        // Clear value
                                        onChange(null);
                                        return;
                                    }
                                    // Open modal
                                }}>
                                {realValue ? (
                                    <Button
                                        variant="tertiary"
                                        theme={theme}
                                        icon={FiX}
                                        className="self-end !px-8"
                                        iconPosition="center"
                                        action={() => onChange(null)}
                                    />
                                ) : (
                                    <Button
                                        variant="tertiary"
                                        theme={theme}
                                        icon={FiExternalLink}
                                        iconType="stroke"
                                        className="self-end !px-8"
                                        iconPosition="center"
                                        action={() => modalOpen()}
                                    />
                                )}
                            </div>
                        </div>

                        {error && (
                            <div className="flex mt-6 -mb-16 input-utility-text">
                                {error && (
                                    <div className="input-utility-text-error">
                                        {errorLabel}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </label>
            <SelectLongListModal
                {...{
                    options: realOptions,
                    theme,
                    onSelect(event) {
                        onChange(event);
                    },
                    onCancel() {
                        modalClose();
                    },
                    title: inputLabel,
                    ...modalState,
                }}
            />
        </>
    ) : null;
};

SelectLongListComponent.propTypes = {
    className: t.string,
    controller: t.object.isRequired,
    create: t.shape({
        fields: t.func.isRequired,
        methods: t.shape({
            add: t.shape({
                label: t.string,
                action: t.func.isRequired,
            }),
        }),
    }),
    defaultValue: t.string,
    disabled: t.bool,
    missingOptionsLabel: t.string,
    name: t.string.isRequired,
    object: t.object.isRequired,
    options: t.oneOfType([
        t.arrayOf(
            t.shape({
                label: t.string,
                value: t.oneOfType([t.string, t.number, t.bool]),
            })
        ),
        t.func,
    ]),
    placeholder: t.string,
    setValue: t.func,
    sort: t.bool,
    theme: t.oneOf(['teal', 'blue', 'blue-alt']),
    unregisterInput: t.bool,
};

SelectLongListComponent.defaultProps = {
    setValue() {},
    sort: true,
    theme: 'teal',
};

export default SelectLongListComponent;
