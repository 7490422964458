// React
import React, { useEffect, useState, useRef } from 'react';

// Packages
import cc from 'classcat';
import { da } from 'date-fns/locale';
import t from 'prop-types';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import { useFormContext, useController } from 'react-hook-form';

import { parse as fnsParse } from 'date-fns';
import dayjs from 'dayjs';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

// Utilities
import { useLabels, useContext } from 'utilities/hooks';

const DateRangeComponent = ({
    controller,
    customValidation,
    customValidationError,
    defaultValue,
    disabled,
    disabledDaysFrom,
    disabledDaysTo,
    fromLabel,
    name,
    object,
    theme,
    toLabel,
    unregisterInput,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const { LOCALE } = useContext();

    const formContext = useFormContext();

    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
        defaultValue: defaultValue,
        rules: {
            validate: {
                isDateFrom: v => (v.from ? dayjs(v.from).isValid() : true),
                isDateTo: v => (v.to ? dayjs(v.to).isValid() : true),
                required: v => (required ? !!v.from && !!v.to : true),
                customValidation: v =>
                    customValidation ? customValidation(v) : true,
            },
        },
    });

    // ///////////////////
    // STATE
    // ///////////////////

    const [showCalendarFrom, setShowCalendarFrom] = useState(false);
    const [inputFromError, setInputFromError] = useState(false);
    const [inputFromDate, setInputFromDate] = useState(
        defaultValue?.from || null
    );
    const [from, setFrom] = useState(
        defaultValue?.from
            ? fnsParse(defaultValue.from, 'y-MM-dd', new Date())
            : null
    );

    const [showCalendarTo, setShowCalendarTo] = useState(false);
    const [inputToError, setInputToError] = useState(false);
    const [inputToDate, setInputToDate] = useState(defaultValue?.to);
    const [to, setTo] = useState(
        defaultValue?.to
            ? fnsParse(defaultValue.to, 'y-MM-dd', new Date())
            : null
    );

    // ///////////////////
    // REFS
    // ///////////////////

    const datesFromWrapper = useRef(null);
    const datesToWrapper = useRef(null);

    // ///////////////////
    // METHODS
    // ///////////////////

    function scrollIntoFocus(element) {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }

    function handleInputFromChange(event) {
        // Get value
        const value = event.currentTarget.value;

        // Always just set input date
        setInputFromDate(value);

        // Check if date is valid format and remove input error
        if (
            dayjs(value, 'YYYY-MM-DD', true).isValid() ||
            dayjs(value, 'YYYY-MM-D', true).isValid() ||
            dayjs(value, 'YYYYMMDD', true).isValid() ||
            dayjs(value, 'YYYYMMD', true).isValid()
        ) {
            setInputFromError(false);
        }
        // If not valid, add input error
        else {
            setInputFromError(true);
        }
    }

    function handleInputFromKeyDown(event) {
        // Show calendar on arrow down
        if (event.key === 'ArrowDown') {
            setShowCalendarFrom(true);
            return;
        }

        // Hide calendar on arrow up
        if (event.key === 'ArrowUp') {
            setShowCalendarFrom(false);
            return;
        }

        // Check for enter key and no input error
        if ((event.key === 'Enter' || event.key === 'Tab') && !inputFromError) {
            // Get value
            // Replace all dashes with empty string
            const inputValue = event.currentTarget.value.replaceAll('-', '');

            // If no input value just return
            if (!inputValue) {
                return;
            }

            // Update calendar date value (parsed with date-fns)
            const parsedDate = fnsParse(inputValue, 'yMMdd', new Date());

            // Check for valid date with dayjs
            if (!dayjs(parsedDate).isValid()) {
                return;
            }

            // Format date to correct format
            const formattedDate = dayjs(parsedDate).format('YYYY-MM-DD');

            // Update calendar date value
            setFrom(parsedDate);

            // Update input date in correct format
            setInputFromDate(formattedDate);

            // Update form value in correct format
            onChange({
                ...value,
                from: formattedDate,
            });

            // Hide calendar and blur
            setShowCalendarFrom(false);
            event.currentTarget.blur();
            return;
        }

        // Check for escape key
        if (event.key === 'Escape') {
            // Reset to previous known date selected on calendar
            setInputFromDate(dayjs(from).format('YYYY-MM-DD'));

            // Remove input error
            setInputFromError(false);

            // Hide calendar and blur
            setShowCalendarFrom(false);
            event.currentTarget.blur();
            return;
        }
    }

    function handleInputFromBlur(event) {
        // Only run if there calendar is hidden and no input error
        // Check for enter key and no input error
        if (!showCalendarFrom && !inputFromError) {
            // Get value
            // Replace all dashes with empty string
            const inputValue = event.currentTarget.value.replaceAll('-', '');

            // If no input value just return
            if (!inputValue) {
                return;
            }

            // Update calendar date value (parsed with date-fns)
            const parsedDate = fnsParse(inputValue, 'yMMdd', new Date());

            // Check for valid date with dayjs
            if (!dayjs(parsedDate).isValid()) {
                return;
            }

            // Format date to correct format
            const formattedDate = dayjs(parsedDate).format('YYYY-MM-DD');

            // Update calendar date value
            setFrom(parsedDate);

            // Update input date in correct format
            setInputFromDate(formattedDate);

            // Update form value in correct format
            onChange({
                ...value,
                from: formattedDate,
            });

            // Hide calendar and blur
            setShowCalendarFrom(false);
            event.currentTarget.blur();
        }
    }

    function handleOnFromDayClick(event) {
        // Update calendar date value
        setFrom(event);

        // Update input date in correct format
        setInputFromDate(dayjs(event).format('YYYY-MM-DD'));

        // Update form value in correct format
        onChange({
            ...value,
            from: dayjs(event).format('YYYY-MM-DD'),
        });

        // Hide calendar
        setShowCalendarFrom(false);
    }

    function handleInputToChange(event) {
        // Get value
        const value = event.currentTarget.value;

        // Always just set input date
        setInputToDate(value);

        // Check if date is valid format and remove input error
        if (
            dayjs(value, 'YYYY-MM-DD', true).isValid() ||
            dayjs(value, 'YYYY-MM-D', true).isValid() ||
            dayjs(value, 'YYYYMMDD', true).isValid() ||
            dayjs(value, 'YYYYMMD', true).isValid()
        ) {
            setInputToError(false);
        }
        // If not valid, add input error
        else {
            setInputToError(true);
        }
    }

    function handleInputToKeyDown(event) {
        // Show calendar on arrow down
        if (event.key === 'ArrowDown') {
            setShowCalendarTo(true);
            return;
        }

        // Hide calendar on arrow up
        if (event.key === 'ArrowUp') {
            setShowCalendarTo(false);
            return;
        }

        // Check for enter key and no input error
        if ((event.key === 'Enter' || event.key === 'Tab') && !inputToError) {
            // Get value
            // Replace all dashes with empty string
            const inputValue = event.currentTarget.value.replaceAll('-', '');

            // If no input value just return
            if (!inputValue) {
                return;
            }

            // Update calendar date value (parsed with date-fns)
            const parsedDate = fnsParse(inputValue, 'yMMdd', new Date());

            // Check for valid date with dayjs
            if (!dayjs(parsedDate).isValid()) {
                return;
            }

            // Format date to correct format
            const formattedDate = dayjs(parsedDate).format('YYYY-MM-DD');

            // Update calendar date value
            setTo(parsedDate);

            // Update input date in correct format
            setInputToDate(formattedDate);

            // Update form value in correct format
            onChange({
                ...value,
                to: formattedDate,
            });

            // Hide calendar and blur
            setShowCalendarTo(false);
            event.currentTarget.blur();
            return;
        }

        // Check for escape key
        if (event.key === 'Escape') {
            // Reset to previous known date selected on calendar
            setInputToDate(dayjs(to).format('YYYY-MM-DD'));

            // Remove input error
            setInputToError(false);

            // Hide calendar and blur
            setShowCalendarTo(false);
            event.currentTarget.blur();
            return;
        }
    }

    function handleInputToBlur(event) {
        // Only run if there calendar is hidden and no input error
        if (!showCalendarTo && !inputToError) {
            // Get value
            // Replace all dashes with empty string
            const inputValue = event.currentTarget.value.replaceAll('-', '');

            // If no input value just return
            if (!inputValue) {
                return;
            }

            // Update calendar date value (parsed with date-fns)
            const parsedDate = fnsParse(inputValue, 'yMMdd', new Date());

            // Check for valid date with dayjs
            if (!dayjs(parsedDate).isValid()) {
                return;
            }

            // Format date to correct format
            const formattedDate = dayjs(parsedDate).format('YYYY-MM-DD');

            // Update calendar date value
            setTo(parsedDate);

            // Update input date in correct format
            setInputToDate(formattedDate);

            // Update form value in correct format
            onChange({
                ...value,
                to: formattedDate,
            });

            // Hide calendar and blur
            setShowCalendarTo(false);
            event.currentTarget.blur();
        }
    }

    function handleOnToDayClick(event) {
        // Update calendar date value
        setTo(event);

        // Update input date in correct format
        setInputToDate(dayjs(event).format('YYYY-MM-DD'));

        // Update form value in correct format
        onChange({
            ...value,
            to: dayjs(event).format('YYYY-MM-DD'),
        });

        // Hide calendar
        setShowCalendarTo(false);
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (formContext && unregisterInput) {
            formContext.unregister(name);
        }
    }, [unregisterInput]);

    useEffect(() => {
        if (defaultValue.from !== from) {
            setInputFromDate(dayjs(defaultValue.from).format('YYYY-MM-DD'));
            setFrom(fnsParse(defaultValue.from, 'y-MM-dd', new Date()));
            setInputToDate(dayjs(defaultValue.to).format('YYYY-MM-DD'));
            setTo(fnsParse(defaultValue.to, 'y-MM-dd', new Date()));
        }
    }, [defaultValue]);

    useEffect(() => {
        if (value.from) {
            setInputFromDate(dayjs(value.from).format('YYYY-MM-DD'));
            setFrom(fnsParse(value.from, 'y-MM-dd', new Date()));
        }
        if (value.to) {
            setInputToDate(dayjs(value.to).format('YYYY-MM-DD'));
            setTo(fnsParse(value.to, 'y-MM-dd', new Date()));
        }
    }, []);

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isBlueAlt = theme === 'blue-alt';
    const isTeal = theme === 'teal';

    const twTheme = {
        blue: {
            300: 'hsl(231, 50%, 50%)',
            10: 'hsl(231, 17%, 96%)',
        },
        'blue-alt': {
            300: 'hsl(231, 50%, 50%)',
            10: 'hsl(231, 17%, 96%)',
        },
        teal: {
            300: 'hsl(203, 50%, 50%)',
            10: 'hsl(203, 17%, 96%)',
        },
    };

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            <label className="flex flex-col" htmlFor={name}>
                {inputLabel && (
                    <span
                        className={cc([
                            'input-label',
                            {
                                'input-label-blue': isBlue,
                                'input-label-blue-alt': isBlueAlt,
                                'input-label-teal': isTeal,
                            },
                        ])}>
                        {inputLabel}
                        {required && (
                            <span>({label('FormCaptureRequired')})</span>
                        )}
                    </span>
                )}
                {subLabel && (
                    <span
                        className={cc([
                            'mt-8 input-sublabel',
                            {
                                'input-sublabel-blue': isBlue,
                                'input-sublabel-blue-alt': isBlueAlt,
                                'input-sublabel-teal': isTeal,
                            },
                        ])}>
                        {subLabel}
                    </span>
                )}
                <div className="grid grid-cols-2 gap-12">
                    <div className="relative flex flex-col col-span-1">
                        <input
                            type="text"
                            disabled={disabled}
                            onClick={() => {
                                setShowCalendarFrom(true);
                                setTimeout(() => {
                                    scrollIntoFocus(datesFromWrapper.current);
                                }, 200);
                            }}
                            onChange={event => handleInputFromChange(event)}
                            onKeyDown={event => handleInputFromKeyDown(event)}
                            onBlur={event => handleInputFromBlur(event)}
                            value={inputFromDate}
                            placeholder="yyyy-mm-dd"
                            className={cc([
                                'input-defaults',
                                {
                                    'input-defaults-blue': isBlue,
                                    'input-defaults-blue-alt': isBlueAlt,
                                    'input-defaults-teal': isTeal,
                                    'input-defaults-error':
                                        error || inputFromError,
                                    'mt-16': inputLabel,
                                },
                            ])}
                        />

                        {showCalendarFrom && (
                            <>
                                <span
                                    ref={datesFromWrapper}
                                    className={cc([
                                        'mb-4 mt-16 input-utility-text',
                                        {
                                            'input-utility-text-blue': isBlue,
                                            'input-utility-text-blue-alt': isBlueAlt,
                                            'input-utility-text-teal': isTeal,
                                        },
                                    ])}>
                                    {fromLabel ||
                                        label('FormCaptureDateRangeFrom')}
                                </span>
                                <DayPicker
                                    locale={LOCALE === 'da' ? da : null}
                                    className={cc([
                                        {
                                            'text-teal-100': isTeal,
                                            'text-blue-100': isBlueAlt,
                                            'text-blue-100': isBlue,
                                        },
                                    ])}
                                    selected={from}
                                    defaultMonth={
                                        from
                                            ? new Date(
                                                  dayjs(from).format('YYYY'),
                                                  dayjs(from).format('M') - 1
                                              )
                                            : null
                                    }
                                    disabled={[disabledDaysFrom]}
                                    onDayClick={event =>
                                        handleOnFromDayClick(event)
                                    }
                                />
                            </>
                        )}
                    </div>
                    <div className="relative flex flex-col col-span-1">
                        <input
                            type="text"
                            disabled={disabled || !from}
                            onClick={() => {
                                setShowCalendarTo(true);
                                setTimeout(() => {
                                    scrollIntoFocus(datesToWrapper.current);
                                }, 200);
                            }}
                            onChange={event => handleInputToChange(event)}
                            onKeyDown={event => handleInputToKeyDown(event)}
                            onBlur={event => handleInputToBlur(event)}
                            value={inputToDate}
                            placeholder="yyyy-mm-dd"
                            className={cc([
                                'input-defaults',
                                {
                                    'input-defaults-blue': isBlue,
                                    'input-defaults-blue-alt': isBlueAlt,
                                    'input-defaults-teal': isTeal,
                                    'input-defaults-error':
                                        error || inputToError,
                                    'mt-16': inputLabel,
                                },
                            ])}
                        />
                        {showCalendarTo && from && (
                            <>
                                <span
                                    ref={datesToWrapper}
                                    className={cc([
                                        'mb-4 mt-16 input-utility-text',
                                        {
                                            'input-utility-text-blue': isBlue,
                                            'input-utility-text-blue-alt': isBlueAlt,
                                            'input-utility-text-teal': isTeal,
                                        },
                                    ])}>
                                    {toLabel || label('FormCaptureDateRangeTo')}
                                </span>
                                <DayPicker
                                    locale={LOCALE === 'da' ? da : null}
                                    className={cc([
                                        {
                                            'text-teal-100': isTeal,
                                            'text-blue-100': isBlue,
                                        },
                                    ])}
                                    selected={to}
                                    defaultMonth={
                                        to
                                            ? new Date(
                                                  dayjs(to).format('YYYY'),
                                                  dayjs(to).format('M') - 1
                                              )
                                            : null
                                    }
                                    disabled={[
                                        disabledDaysTo,
                                        {
                                            from: new Date(1970, 1, 1),
                                            to: from,
                                        },
                                    ]}
                                    onDayClick={event =>
                                        handleOnToDayClick(event)
                                    }
                                />
                            </>
                        )}
                    </div>
                </div>
                {(customValidationError || error) && (
                    <div className="flex mt-6 -mb-16 input-utility-text">
                        <div className="input-utility-text-error">
                            {error?.type === 'customValidation' &&
                                customValidationError}
                            {error &&
                                error?.type !== 'customValidation' &&
                                errorLabel}
                        </div>
                    </div>
                )}
            </label>
            <style global jsx>
                {`
                    .rdp-day {
                        padding-top: 5px;
                    }
                    .rdp {
                        margin: 0;
                        --rdp-accent-color: ${twTheme[theme][300]};
                        --rdp-background-color: ${twTheme[theme][10]};
                    }
                `}
            </style>
        </>
    );
};

DateRangeComponent.propTypes = {
    controller: t.object.isRequired,
    customValidation: t.func,
    customValidationError: t.string,
    defaultValue: t.shape({ from: t.string, to: t.string }),
    disabled: t.bool,
    disabledDaysFrom: t.func,
    disabledDaysTo: t.func,
    fromLabel: t.string,
    name: t.string.isRequired,
    object: t.object.isRequired,
    theme: t.oneOf(['teal', 'blue', 'blue-alt']),
    toLabel: t.string,
};

DateRangeComponent.defaultProps = {
    defaultValue: {
        from: null,
        to: null,
    },
    disabledDaysFrom() {
        return false;
    },
    disabledDaysTo() {
        return false;
    },
    theme: 'teal',
};

export default DateRangeComponent;
