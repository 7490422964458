// React
import React, { useEffect } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useFormContext, useController } from 'react-hook-form';

// Utilities
import { useLabels } from 'utilities/hooks';

const RangeComponent = ({
    controller,
    defaultValue,
    disabled,
    maxValue,
    minValue,
    name,
    object,
    placeholder,
    theme,
    unregisterInput,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
        defaultValue,
        rules: {
            validate: {
                isNumberFrom: v =>
                    v?.length > 0
                        ? /^(0|[1-9][0-9]*)$/.test(v.from) && v.from < v.to
                        : true,
                isNumberTo: v =>
                    v?.length > 0
                        ? /^(0|[1-9][0-9]*)$/.test(v.to) && v.to > v.from
                        : true,
                rangeMin: v => (minValue && v ? v.from >= minValue : true),
                rangeMax: v => (maxValue && v ? v.to <= maxValue : true),
                required: v => (required ? !!v.from && !!v.to : true),
            },
        },
    });

    const formContext = useFormContext();

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (formContext && unregisterInput) {
            formContext.unregister(name);
        }
    }, [unregisterInput]);

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return !unregisterInput ? (
        <label className="flex flex-col" htmlFor={name} ref={ref}>
            {inputLabel && (
                <span
                    className={cc([
                        'input-label',
                        {
                            'input-label-blue': isBlue,
                            'input-label-teal': isTeal,
                        },
                    ])}>
                    {inputLabel}
                    {required && <span>({label('FormCaptureRequired')})</span>}
                </span>
            )}
            {subLabel && (
                <span
                    className={cc([
                        'mt-8 input-sublabel',
                        {
                            'input-sublabel-blue': isBlue,
                            'input-sublabel-teal': isTeal,
                        },
                    ])}>
                    {subLabel}
                </span>
            )}
            <div className={cc(['space-x-12 flex', { 'mt-16': inputLabel }])}>
                <div className="flex flex-col grow">
                    <span
                        className={cc([
                            'mb-4 input-utility-text',
                            {
                                'input-utility-text-blue': isBlue,
                                'input-utility-text-teal': isTeal,
                            },
                        ])}>
                        {label('FormCaptureDateRangeFrom')}{' '}
                        {typeof minValue === 'number' && `(${minValue})`}
                    </span>
                    <input
                        name={name}
                        type="tel"
                        defaultValue={value?.from}
                        required={required}
                        disabled={disabled}
                        onChange={event => {
                            // Opdate value
                            if (event.target.value.length > 0) {
                                onChange({
                                    from: parseInt(event.target.value, 10),
                                    to: value?.to,
                                });
                            } else {
                                onChange({
                                    from: 0,
                                    to: value?.to,
                                });
                            }
                        }}
                        placeholder={
                            placeholder || label('FormCaptureTextEntryEmpty')
                        }
                        className={cc([
                            'text-right',
                            'input-defaults appearance-none',
                            {
                                'input-defaults-blue': isBlue,
                                'input-defaults-teal': isTeal,
                                'input-defaults-error': error,
                            },
                        ])}
                    />
                </div>
                <div className="flex flex-col grow">
                    <span
                        className={cc([
                            'mb-4 input-utility-text',
                            {
                                'input-utility-text-blue': isBlue,
                                'input-utility-text-teal': isTeal,
                            },
                        ])}>
                        {label('FormCaptureDateRangeTo')}{' '}
                        {typeof maxValue === 'number' && `(${maxValue})`}
                    </span>
                    <input
                        type="tel"
                        required={required}
                        defaultValue={value?.to}
                        onChange={event => {
                            // Opdate value
                            if (event.target.value.length > 0) {
                                onChange({
                                    from: value?.from,
                                    to: parseInt(event.target.value, 10),
                                });
                            } else {
                                onChange({
                                    from: value?.from,
                                    to: 0,
                                });
                            }
                        }}
                        placeholder={
                            placeholder || label('FormCaptureTextEntryEmpty')
                        }
                        className={cc([
                            'text-right',
                            'input-defaults appearance-none',
                            {
                                'input-defaults-blue': isBlue,
                                'input-defaults-teal': isTeal,
                                'input-defaults-error': error,
                            },
                        ])}
                    />
                </div>
            </div>

            {error && (
                <div className="flex mt-6 -mb-16 input-utility-text">
                    {error && (
                        <div className="input-utility-text-error">
                            {errorLabel}
                        </div>
                    )}
                </div>
            )}
        </label>
    ) : null;
};

RangeComponent.propTypes = {
    controller: t.object.isRequired,
    defaultValue: t.shape({
        from: t.oneOfType([t.number, t.string]),
        to: t.oneOfType([t.number, t.string]),
    }),
    disabled: t.bool,
    maxValue: t.number,
    minValue: t.number,
    name: t.string.isRequired,
    object: t.object.isRequired,
    placeholder: t.string,
    theme: t.oneOf(['teal', 'blue']),
    unregisterInput: t.bool,
};

RangeComponent.defaultProps = {
    maxValue: null,
    minValue: 0,
    theme: 'teal',
};

export default RangeComponent;
