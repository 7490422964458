// React
import React from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';
import _groupBy from 'lodash.groupby';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import Pill from 'components/pill';

const Tags = ({ tags, theme, variant, className }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { tagLabel } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className={cc(['flex flex-col space-y-12', className])}>
            {tags.map(tagSet => {
                const { collection, tags: tagSetTags } = tagSet;
                return (
                    <div key={collection?.Account_Name__c}>
                        <p
                            className={cc([
                                't-sh6',
                                {
                                    'text-teal-60': theme === 'teal',
                                    'text-blue-60': theme === 'blue',
                                },
                            ])}>
                            {collection?.Account_Name__c}
                        </p>
                        <div className="flex flex-col">
                            <div>
                                <p
                                    className={cc([
                                        't-footnote mb-2',
                                        {
                                            'text-teal-80': theme === 'teal',
                                            'text-blue-80': theme === 'blue',
                                        },
                                    ])}>
                                    {collection.Tag__r?.Name}
                                </p>

                                <div className="flex flex-wrap gap-x-8 gap-y-0">
                                    {tagSetTags.map(tag => (
                                        <Pill
                                            key={tag?.Id}
                                            variant={variant}
                                            theme={theme}>
                                            {tagLabel(tag)}
                                        </Pill>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

Tags.propTypes = {
    tags: t.array.isRequired,
    theme: t.oneOf(['blue', 'teal']),
    variant: t.oneOf(['default', 'light', 'dark', 'darkest']),
};

Tags.defaultProps = {
    tags: [],
    theme: 'teal',
    variant: 'default',
};

export default Tags;
