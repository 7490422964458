// React
import React, { useState, useEffect, useRef } from 'react';

// Packages
import cc from 'classcat';
import { da } from 'date-fns/locale';
import t from 'prop-types';
import { useController } from 'react-hook-form';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

// Utilities
import { useFormat, useContext } from 'utilities/hooks';

// Components

// Icons
import { FiX } from 'react-icons/fi';

const SearchFilterDateComponent = ({ controller, label, name, options }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { LOCALE } = useContext();
    const format = useFormat();

    // Controller from useForm
    const {
        field: { onChange, value },
    } = useController({
        name,
        control: controller,
    });

    // ///////////////////
    // STATE / REFS
    // ///////////////////

    const [listWrapper, toggleListWrapper] = useState(false);

    // Ref: Mobile navigation wrapper
    const listWrapperRef = useRef(null);

    // ///////////////////
    // METHODS
    // ///////////////////

    // Function: Event wrapper for closing outside click
    function handleClick(event) {
        if (
            listWrapper &&
            listWrapperRef.current &&
            !listWrapperRef.current.contains(event.target)
        ) {
            toggleListWrapper(false);
        }
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    // Effect: Catch outside clicks and close
    useEffect(() => {
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [listWrapper]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="relative flex m-8">
            <button
                className={cc([
                    ' py-6 text-left text-blue-300 outline-none pt-11 focus:ring-2 bg-blue-20 t-small focus:ring-blue-100 focus:outline-none',
                    {
                        'rounded-l-4 pr-8 pl-16': value,
                        'rounded-4 px-16': !value,
                    },
                ])}
                onClick={event => {
                    event.preventDefault();
                    toggleListWrapper(!listWrapper);
                }}>
                {`${label} `}
                <span className="font-bold">
                    {value ? format.date(value) : '...'}
                </span>
            </button>
            {value && (
                <button
                    onClick={() => onChange(null)}
                    className="pl-8 pr-16 text-blue-300 outline-none focus:ring-2 bg-blue-20 t-small focus:ring-blue-100 focus:outline-none rounded-r-4">
                    <FiX />
                </button>
            )}
            <div
                ref={listWrapperRef}
                className={cc([
                    'absolute flex flex-col p-16 mt-8 space-y-16 text-blue-300 t-h6 bg-blue-20 top-full rounded-4 z-above transition-default',
                    {
                        'opacity-100 pointer-events-auto': listWrapper,
                        'opacity-0 pointer-events-none': !listWrapper,
                    },
                ])}>
                <DayPicker
                    locale={LOCALE === 'da' ? da : null}
                    firstDayOfWeek={1}
                    selected={value}
                    onDayClick={event => {
                        toggleListWrapper(!listWrapper);
                        if (event === value) {
                            onChange(null);
                        } else onChange(event);
                    }}
                />
            </div>
            <style global jsx>
                {`
                    .rdp-day {
                        padding-top: 5px;
                    }
                    .rdp {
                        --rdp-accent-color: hsl(231, 50%, 50%);
                        --rdp-background-color: hsl(231, 17%, 96%);
                    }
                `}
            </style>
        </div>
    );
};

SearchFilterDateComponent.propTypes = {
    name: t.string,
};

SearchFilterDateComponent.defaultProps = {};

export default SearchFilterDateComponent;
