// React
import React, { useEffect, useState, useRef } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useFormContext, useController } from 'react-hook-form';
import { nanoid } from 'nanoid';
import { Combobox } from '@headlessui/react';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import Button from 'components/button';
import EmptyState from './../emptyState';

// Icons
import { FiX, FiChevronDown, FiPlus, FiCheck } from 'react-icons/fi';

const SelectListComponent = ({
    controller,
    defaultValue,
    disabled,
    listMaxLength,
    maxLength,
    missingOptionsLabel,
    name,
    nested,
    object,
    options,
    selectLabel,
    selectPlaceholder,
    setValue,
    showText,
    sort,
    textLabel,
    textPlaceholder,
    theme,
    unregisterInput,
    disableText,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
        defaultValue: defaultValue.filter(item => item.selectValue.length > 0),
        rules: { required },
    });

    const {
        field: { value: removedValue },
    } = useController({
        name: `${name}_Removed`,
        control: controller,
        defaultValue: [],
    });

    const formContext = useFormContext();

    // ///////////////////
    // STATE
    // ///////////////////

    const [asyncOptionsFetched, setAsyncOptionsFetched] = useState(false);
    const [loadedOptions, setLoadedOptions] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [allowAutoOpen, setAllowAutoOpen] = useState(false);
    const [list, setList] = useState([
        { selectValue: '', textValue: '', id: nanoid() },
    ]);

    // ///////////////////
    // METHODS
    // ///////////////////

    function onSelectChange(event, item) {
        // Update removed value
        setValue(`${name}_Removed`, [...removedValue, item]);

        // Get next list
        const nextList = [
            ...list.map(listItem =>
                listItem.id === item.id
                    ? {
                          ...listItem,
                          selectValue: event,
                          ...(disableText(event) ? { textValue: '' } : {}),
                      }
                    : listItem
            ),
        ];

        // Update current list
        setList(nextList);

        // Update form
        onChange(nextList.filter(item => item.selectValue.length > 0));
    }

    function onTextChange(event, item) {
        // Get next list
        const nextList = [
            ...list.map(listItem =>
                listItem.id === item.id
                    ? { ...listItem, textValue: event.target.value }
                    : listItem
            ),
        ];

        // Update current list
        setList(nextList);
    }

    function getPlaceholder() {
        if (loadingOptions) {
            return label('FormCaptureSelectLoadingOptions');
        } else {
            if (loadedOptions.length > 0) {
                return selectPlaceholder || label('FormCaptureSelectEmpty');
            } else {
                return label('FormCaptureSelectNoOptions');
            }
        }
    }

    // ///////////////////
    // DATA
    // ///////////////////

    const columnWidth = {
        'col-span-full': !showText && value?.length === 0,
        'col-span-11': !showText && value?.length > 0,
        'col-span-6': showText,
    };

    const missingOptions = missingOptionsLabel && loadedOptions.length === 0;

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        // Assume normal options
        if (Array.isArray(options)) {
            setLoadedOptions(options);
        }
        // Or perhaps async options
        else {
            async function getOptions() {
                setLoadingOptions(true);
                setLoadedOptions(await options());
                setLoadingOptions(false);
                setAsyncOptionsFetched(true);
            }
            if (!asyncOptionsFetched) {
                getOptions();
            }
        }
    }, [options]);

    // Set value from beginning
    useEffect(() => {
        if (defaultValue.length > 0) {
            setList(
                defaultValue.map(item => ({
                    selectValue: item.selectValue,
                    textValue: item.textValue ?? '',
                    id: nanoid(),
                    disabled: item.disabled,
                    optionDisabled: item.optionDisabled,
                }))
            );
            setValue(
                name,
                defaultValue.map(item => ({
                    selectValue: item.selectValue,
                    textValue: item.textValue ?? '',
                    id: nanoid(),
                    disabled: item.disabled,
                    optionDisabled: item.optionDisabled,
                }))
            );
        }
        // Potentially bugged here, as defaultValue probably should be
        // a part of the deps array, if we want to update on defaultValue as well
        // Perhaps we should do an async defaultValue like options to support async states
        // of default values.
    }, [loadedOptions]);

    // Update local state when using setValue
    useEffect(() => {
        if (value && value.length > 0) {
            setList(
                value.map(item => ({
                    selectValue: item.selectValue,
                    textValue: item.textValue ?? '',
                    id: nanoid(),
                    disabled: item.disabled,
                    optionDisabled: item.optionDisabled,
                }))
            );
        }
    }, [value, loadedOptions]);

    useEffect(() => {
        if (formContext && unregisterInput) {
            formContext.unregister(name);
        }
    }, [unregisterInput]);

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isBlueAlt = theme === 'blue-alt';
    const isTeal = theme === 'teal';
    const isNested = nested;

    // ///////////////////
    // RENDER
    // ///////////////////

    return !unregisterInput ? (
        <label className="flex flex-col" htmlFor={name} ref={ref}>
            {inputLabel && (
                <span
                    className={cc([
                        'input-label',
                        {
                            '!t-h6': isNested,
                            't-h5': !isNested,
                            'input-label-blue': isBlue,
                            'input-label-blue-alt': isBlueAlt,
                            'input-label-teal': isTeal,
                        },
                    ])}>
                    {inputLabel}
                    {required && <span>({label('FormCaptureRequired')})</span>}
                </span>
            )}
            {subLabel && (
                <span
                    className={cc([
                        'mt-8 input-sublabel',
                        {
                            '!t-sh6': isNested,
                            'input-sublabel-blue': isBlue,
                            'input-sublabel-blue-alt': isBlueAlt,
                            'input-sublabel-teal': isTeal,
                        },
                    ])}>
                    {subLabel}
                </span>
            )}
            <div
                className={cc([
                    'flex flex-col',
                    { 'mt-8': inputLabel || subLabel },
                ])}>
                {missingOptions && (
                    <EmptyState theme={theme} label={missingOptionsLabel} />
                )}

                {!missingOptions && (
                    <>
                        {(selectLabel || textLabel) && (
                            <div
                                className={cc([
                                    'grid grid-cols-12 gap-12 mb-4 input-utility-text',
                                    {
                                        'input-utility-text-blue': isBlue,
                                        'input-utility-text-teal': isTeal,
                                    },
                                ])}>
                                <span className={cc([columnWidth])}>
                                    {selectLabel || ''}
                                </span>
                                <span className={cc([columnWidth])}>
                                    {textLabel || ''}
                                </span>
                            </div>
                        )}
                        <div className="flex flex-col space-y-12">
                            {list.map(item => {
                                return (
                                    <ListRow
                                        key={item.id}
                                        {...{
                                            columnWidth,
                                            disabled,
                                            error,
                                            getPlaceholder,
                                            isBlue,
                                            isBlueAlt,
                                            isTeal,
                                            item,
                                            list,
                                            loadedOptions,
                                            maxLength,
                                            name,
                                            onChange,
                                            onSelectChange,
                                            onTextChange,
                                            sort,
                                            setList,
                                            setLoadedOptions,
                                            setValue,
                                            showText,
                                            textPlaceholder,
                                            theme,
                                            value,
                                            removedValue,
                                            allowAutoOpen,
                                            disableText,
                                        }}
                                    />
                                );
                            })}
                        </div>

                        {error && (
                            <div className="flex mt-6 input-utility-text">
                                {error && (
                                    <div className="input-utility-text-error">
                                        {errorLabel}
                                    </div>
                                )}
                            </div>
                        )}

                        <AddButton
                            {...{
                                list,
                                listMaxLength,
                                options: loadedOptions,
                                setList,
                                value,
                                theme,
                                setAllowAutoOpen,
                            }}
                        />
                    </>
                )}
            </div>
        </label>
    ) : null;
};

const ListRow = ({
    columnWidth,
    disabled,
    error,
    getPlaceholder,
    isBlue,
    isBlueAlt,
    isTeal,
    item,
    list,
    loadedOptions,
    maxLength,
    name,
    onChange,
    onSelectChange,
    onTextChange,
    setList,
    setLoadedOptions,
    setValue,
    sort,
    showText,
    textPlaceholder,
    theme,
    value,
    removedValue,
    allowAutoOpen,
    disableText,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const inputRef = useRef();

    // ///////////////////
    // STATE
    // ///////////////////

    const [query, setQuery] = useState('');
    const [active, setActive] = useState(false);

    // ///////////////////
    // DATA
    // ///////////////////

    const realOptions = loadedOptions
        .sort((a, b) => (sort ? a?.label?.localeCompare(b?.label) : true))
        .filter(option => !option.disabledInSelect)
        .filter(option =>
            query === ''
                ? true
                : option.label
                      .toLowerCase()
                      .replace(/\s+/g, '')
                      .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

    const realValue = item.selectValue
        ? loadedOptions.find(option => option.value === item.selectValue)
        : null;

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (inputRef?.current && !active) {
            inputRef?.current?.blur();
        }
        if (active) {
            inputRef?.current?.focus();
            inputRef?.current?.select();
        }
    }, [active]);

    useEffect(() => {
        if (allowAutoOpen) {
            if (inputRef?.current && item.selectValue.length === 0) {
                inputRef?.current?.focus();
                setTimeout(() => {
                    inputRef?.current?.focus();
                    inputRef?.current?.select();
                }, 100);
            }
        }
    }, [allowAutoOpen]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div>
            {/* Select / Input */}
            <div className="grid grid-cols-12 gap-12">
                {/* Select */}
                <div
                    className={cc(['relative flex items-center', columnWidth])}>
                    <Combobox
                        value={realValue}
                        onChange={event => {
                            onSelectChange(event.value, item);

                            setTimeout(() => {
                                setActive(false);
                                setQuery('');
                            }, 100);
                        }}>
                        {({ open }) => (
                            <>
                                {disabled ||
                                loadedOptions.length === 0 ||
                                item.disabled ? (
                                    <input
                                        className={cc([
                                            'input-defaults',
                                            'appearance-none grow !pr-[32px] max-w-full',
                                            {
                                                'input-defaults-blue': isBlue,
                                                'input-defaults-blue-alt': isBlueAlt,
                                                'input-defaults-teal': isTeal,
                                                'input-defaults-error': error,
                                            },
                                        ])}
                                        placeholder={getPlaceholder()}
                                        disabled
                                    />
                                ) : (
                                    <Combobox.Input
                                        onFocus={() => !open && setActive(true)}
                                        onBlur={event => {
                                            setTimeout(() => {
                                                setActive(false);
                                                setQuery('');
                                            }, 100);
                                        }}
                                        ref={inputRef}
                                        className={cc([
                                            'input-defaults',
                                            'appearance-none grow !pr-[32px] max-w-full',
                                            {
                                                'input-defaults-blue': isBlue,
                                                'input-defaults-blue-alt': isBlueAlt,
                                                'input-defaults-teal': isTeal,
                                                'input-defaults-error': error,
                                            },
                                        ])}
                                        displayValue={item =>
                                            item ? item.label : null
                                        }
                                        placeholder={getPlaceholder()}
                                        onChange={event =>
                                            setQuery(event.target.value)
                                        }
                                    />
                                )}
                                {loadedOptions.length > 0 && (
                                    <>
                                        <Combobox.Button
                                            onClick={() =>
                                                open || active
                                                    ? setActive(false)
                                                    : setActive(true)
                                            }
                                            className="absolute inset-y-0 right-0 flex items-center justify-center w-32">
                                            <FiChevronDown className="stroke-current" />
                                        </Combobox.Button>
                                        {(open || active) && (
                                            <Combobox.Options
                                                static
                                                style={{
                                                    top: 'calc(100% + 5px)',
                                                }}
                                                className={cc([
                                                    'input-defaults-select-options',
                                                    {
                                                        'input-defaults-select-options-blue': isBlue,
                                                        'input-defaults-select-options-blue-alt': isBlueAlt,
                                                        'input-defaults-select-options-teal': isTeal,
                                                    },
                                                ])}>
                                                {realOptions.length > 0 ? (
                                                    realOptions.map(
                                                        (option, index) => {
                                                            const disabledOption =
                                                                list
                                                                    .map(
                                                                        l =>
                                                                            l.selectValue
                                                                    )
                                                                    .includes(
                                                                        option.value
                                                                    ) ||
                                                                option.optionDisabled;
                                                            return (
                                                                <Combobox.Option
                                                                    onMouseDown={event => {
                                                                        event.target.click();
                                                                    }}
                                                                    key={`${option.value}-${index}`}
                                                                    className={({
                                                                        active,
                                                                        selected,
                                                                    }) =>
                                                                        cc([
                                                                            'input-defaults-select-option',
                                                                            {
                                                                                'opacity-70': disabledOption,
                                                                                'input-defaults-select-option-blue': isBlue,
                                                                                'input-defaults-select-option-blue-alt': isBlueAlt,
                                                                                'input-defaults-select-option-teal': isTeal,
                                                                                'input-defaults-select-option-active-blue':
                                                                                    active &&
                                                                                    isBlue,
                                                                                'input-defaults-select-option-active-blue-alt':
                                                                                    active &&
                                                                                    isBlueAlt,
                                                                                'input-defaults-select-option-active-teal':
                                                                                    active &&
                                                                                    isTeal,
                                                                                'input-defaults-select-option-selected': selected,
                                                                            },
                                                                        ])
                                                                    }
                                                                    value={
                                                                        option
                                                                    }
                                                                    disabled={
                                                                        disabledOption
                                                                    }>
                                                                    {({
                                                                        selected,
                                                                    }) => (
                                                                        <div className="relative flex items-center">
                                                                            {selected && (
                                                                                <FiCheck className="absolute" />
                                                                            )}
                                                                            <span className="ml-24">
                                                                                {
                                                                                    option.label
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </Combobox.Option>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <div
                                                        className={cc([
                                                            'input-defaults-select-option',
                                                            {
                                                                'input-defaults-select-option-blue': isBlue,
                                                                'input-defaults-select-option-blue-alt': isBlueAlt,
                                                                'input-defaults-select-option-teal': isTeal,
                                                            },
                                                        ])}>
                                                        {label(
                                                            'FormCaptureSelectNothingFound'
                                                        )}
                                                    </div>
                                                )}
                                            </Combobox.Options>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </Combobox>
                </div>

                {/* Input */}
                {showText && (
                    <input
                        type="text"
                        maxLength={maxLength ? maxLength : 'none'}
                        disabled={disabled || disableText(item?.selectValue)}
                        defaultValue={item.textValue}
                        placeholder={
                            textPlaceholder ||
                            label('FormCaptureTextEntryEmpty')
                        }
                        onChange={event => {
                            onTextChange(event, item);
                        }}
                        onBlur={() => {
                            onChange(list);
                        }}
                        className={cc([
                            {
                                'col-span-6': showText && value?.length === 0,
                                'col-span-5': showText && value?.length > 0,
                            },
                            'input-defaults',
                            {
                                'input-defaults-blue': isBlue,
                                'input-defaults-teal': isTeal,
                                'input-defaults-error': error,
                            },
                        ])}
                    />
                )}

                <DeleteButton
                    {...{
                        item,
                        list,
                        name,
                        onChange,
                        setList,
                        setValue,
                        value,
                        removedValue,
                        loadedOptions,
                        setLoadedOptions,
                        theme,
                    }}
                />
            </div>
        </div>
    );
};

const DeleteButton = ({
    item,
    list,
    name,
    onChange,
    setList,
    setValue,
    removedValue,
    value,
    loadedOptions,
    setLoadedOptions,
    theme,
}) => {
    // ///////////////////
    // METHODS
    // ///////////////////

    function deleteAction() {
        // Update removed value
        setValue(`${name}_Removed`, [...removedValue, item]);

        // Reset list if only one thing is selected
        if (value.length === 1 && list.length === 1) {
            // Update current list
            setList([
                {
                    selectValue: '',
                    textValue: '',
                    id: nanoid(),
                },
            ]);

            // Update form
            onChange([]);
        }

        // Remove empty list item
        else if (value.length > 0 && list.length === value.length + 1) {
            // Update current list
            setList(list.filter(l => l.selectValue));
        }

        // Delete item by id
        else if (value.length > 0 && list.length === value.length) {
            // Get next list by filtering out current id
            const nextList = [...list.filter(l => l.id !== item.id)];

            // Update current list
            setList(nextList);

            // Update form
            onChange(nextList);
        }

        // Update loaded options
        loadedOptions.find(x => x.value).optionDisabled = false;

        // Update options to allow
        setLoadedOptions(loadedOptions);
    }

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        value?.length > 0 && (
            <div className="flex justify-end col-span-1">
                <Button
                    variant="tertiary"
                    theme={theme}
                    icon={FiX}
                    disabled={item.disabled}
                    className="self-end !px-8"
                    iconPosition="center"
                    action={deleteAction}
                />
            </div>
        )
    );
};

const AddButton = ({
    list,
    listMaxLength,
    options,
    setList,
    value,
    theme,
    setAllowAutoOpen,
}) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        [
            listMaxLength > 1,
            options.length > 1,
            list.length < options.length,
            list.length === value?.length,
            list.length < listMaxLength,
        ].every(x => x) && (
            <Button
                variant="secondary"
                theme={theme}
                className="self-start mt-12"
                icon={FiPlus}
                iconPosition="center"
                action={() => {
                    setAllowAutoOpen(true);
                    setList([
                        ...list,
                        { selectValue: '', textValue: '', id: nanoid() },
                    ]);
                }}
            />
        )
    );
};

SelectListComponent.propTypes = {
    controller: t.object.isRequired,
    defaultValue: t.arrayOf(
        t.shape({
            selectValue: t.oneOfType([t.string, t.number]),
            textValue: t.oneOfType([t.string, t.number]),
        })
    ),
    disabled: t.bool,
    listMaxLength: t.number,
    maxLength: t.number,
    missingOptionsLabel: t.string,
    name: t.string.isRequired,
    nested: t.bool,
    object: t.object.isRequired,
    options: t.oneOfType([
        t.func,
        t.arrayOf(
            t.shape({
                label: t.string,
                value: t.oneOfType([t.string, t.number, t.bool]),
            })
        ),
    ]),
    selectLabel: t.string,
    selectPlaceholder: t.string,
    setValue: t.func,
    showText: t.bool,
    disableText: t.func,
    sort: t.bool,
    textLabel: t.string,
    textPlaceholder: t.string,
    theme: t.oneOf(['teal', 'blue', 'blue-alt']),
    unregisterInput: t.bool,
};

SelectListComponent.defaultProps = {
    options: [],
    defaultValue: [],
    nested: false,
    showText: false,
    listMaxLength: 5,
    theme: 'teal',
    setValue() {},
    sort: true,
    disableText() {
        return false;
    },
};

export default SelectListComponent;
