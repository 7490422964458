// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities

// Components

const InputWrapperComponent = ({ children }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return <div className="flex flex-col pb-32 space-y-36">{children}</div>;
};

InputWrapperComponent.propTypes = {};

InputWrapperComponent.defaultProps = {};

export default InputWrapperComponent;
