// React
import React, { useEffect, useState } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useController } from 'react-hook-form';

// Utilities
import { useLabels } from 'utilities/hooks';

const LongTextComponent = ({
    controller,
    defaultValue,
    disabled,
    display,
    maxLength,
    name,
    object,
    placeholder,
    setValue,
    theme,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
        defaultValue: defaultValue,
        rules: { maxLength, required },
    });

    // ///////////////////
    // STATE
    // ///////////////////

    const [lengthValue, setLengthValue] = useState(0);

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (value) {
            setLengthValue(value.length);
        }
    }, []);

    // Default value
    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
            setLengthValue(defaultValue.length);
        }
    }, [defaultValue]);

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isBlueAlt = theme === 'blue-alt';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <label className="flex flex-col">
            {inputLabel && (
                <span
                    className={cc([
                        'input-label',
                        {
                            'input-label-blue': isBlue,
                            'input-label-blue-alt': isBlueAlt,
                            'input-label-teal': isTeal,
                        },
                    ])}>
                    {inputLabel}
                    {required && <span>({label('FormCaptureRequired')})</span>}
                </span>
            )}
            {subLabel && (
                <span
                    className={cc([
                        'mt-8 input-sublabel',
                        {
                            'input-sublabel-blue': isBlue,
                            'input-sublabel-blue-alt': isBlueAlt,
                            'input-sublabel-teal': isTeal,
                        },
                    ])}>
                    {subLabel}
                </span>
            )}
            {display ? (
                <div
                    className={cc([
                        'ml-8 mt-4 t-body',
                        {
                            'text-blue-60': isBlue,
                            'text-blue-60': isBlueAlt,
                            'text-teal-60': isTeal,
                        },
                    ])}
                    dangerouslySetInnerHTML={{ __html: value }}
                />
            ) : (
                <>
                    {' '}
                    <textarea
                        ref={ref}
                        defaultValue={defaultValue}
                        maxLength={maxLength}
                        disabled={disabled}
                        placeholder={
                            placeholder || label('FormCaptureTextEntryEmpty')
                        }
                        onChange={event => {
                            const newValue = event.target.value;
                            // Local value state
                            setLengthValue(newValue.length);
                            onChange(newValue);
                        }}
                        className={cc([
                            'input-defaults',
                            '!h-[144px] resize-none',
                            {
                                'input-defaults-blue': isBlue,
                                'input-defaults-blue-alt': isBlueAlt,
                                'input-defaults-teal': isTeal,
                                'input-defaults-error': error,
                                'mt-16': inputLabel,
                            },
                        ])}
                    />
                    {(maxLength > 0 || error) && (
                        <div className="flex mt-6 -mb-16 input-utility-text">
                            {error && (
                                <div className="input-utility-text-error">
                                    {errorLabel}
                                </div>
                            )}
                            {maxLength > 0 && (
                                <div
                                    className={cc([
                                        'ml-auto text-right',
                                        {
                                            'input-utility-text-blue': isBlue,
                                            'input-utility-text-blue-alt': isBlueAlt,
                                            'input-utility-text-teal': isTeal,
                                        },
                                    ])}>
                                    {lengthValue} / {maxLength.toString()}
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </label>
    );
};

LongTextComponent.propTypes = {
    controller: t.object.isRequired,
    defaultValue: t.string,
    disabled: t.bool,
    display: t.bool,
    maxLength: t.number,
    name: t.string.isRequired,
    object: t.object.isRequired,
    placeholder: t.string,
    setValue: t.func,
    theme: t.oneOf(['teal', 'blue', 'blue-alt']),
};

LongTextComponent.defaultProps = {
    disabled: false,
    display: false,
    theme: 'teal',
    setValue() {},
};

export default LongTextComponent;
