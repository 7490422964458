// React
import React, { useState, useEffect, useRef } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useController } from 'react-hook-form';
import { Combobox } from '@headlessui/react';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components

// Icons
import { FiCircle, FiCheckCircle, FiX } from 'react-icons/fi';

const SearchFilterMultiselectComponent = ({
    controller,
    label: inputLabel,
    name,
    options,
    sort,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const {
        field: { onChange, value },
    } = useController({
        name,
        defaultValue: [],
        control: controller,
    });
    const { label } = useLabels();

    // ///////////////////
    // STATE
    // ///////////////////

    const [listWrapper, toggleListWrapper] = useState(false);
    const [query, setQuery] = useState('');

    // ///////////////////
    // REFS
    // ///////////////////

    const listWrapperRef = useRef(null);
    const inputRef = useRef();

    // ///////////////////
    // METHODS
    // ///////////////////

    function handleClick(event) {
        if (
            listWrapper &&
            listWrapperRef.current &&
            !listWrapperRef.current.contains(event.target)
        ) {
            toggleListWrapper(false);
        }
    }

    function toggleItemFromValues(item) {
        // Check for item in list
        const itemExists = value.includes(item.value);

        // Remove
        if (itemExists) {
            return [...value.filter(valueItem => item.value !== valueItem)];
        }

        // Add
        return [...value, item.value];
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [listWrapper]);

    // ///////////////////
    // DATA
    // ///////////////////

    const realOptions = options
        .sort((a, b) => (sort ? a?.label?.localeCompare(b?.label) : true))
        .filter(option => !option.disabledInSelect)
        .filter(option =>
            query === ''
                ? true
                : option.label
                      .toLowerCase()
                      .replace(/\s+/g, '')
                      .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="relative flex m-8">
            <button
                className={cc([
                    'py-6 text-left text-blue-300 outline-none pt-11 focus:ring-2 bg-blue-20 t-small focus:ring-blue-100 focus:outline-none',
                    {
                        'rounded-l-4 pr-8 pl-16': value.length > 0,
                        'rounded-4 px-16': value.length === 0,
                    },
                ])}
                onClick={event => {
                    event.preventDefault();
                    toggleListWrapper(!listWrapper);
                }}>
                {`${inputLabel} `}
                <span className="font-bold">
                    {value.length > 0
                        ? value
                              .map(v => options.find(o => v === o.value).label)
                              .join(', ')
                        : '...'}
                </span>
            </button>
            {value.length > 0 && (
                <button
                    onClick={() => onChange([])}
                    className="pl-8 pr-16 text-blue-300 outline-none focus:ring-2 bg-blue-20 t-small focus:ring-blue-100 focus:outline-none rounded-r-4">
                    <FiX />
                </button>
            )}
            <div
                ref={listWrapperRef}
                className={cc([
                    'absolute flex flex-col p-16 mt-8 space-y-16 text-blue-300 t-h6 bg-blue-20 top-full rounded-4 z-above transition-default',
                    {
                        'opacity-100 pointer-events-auto': listWrapper,
                        'opacity-0 pointer-events-none': !listWrapper,
                    },
                ])}>
                <Combobox
                    onChange={event => {
                        // Get new values list
                        const newList = toggleItemFromValues(event);
                        onChange(newList);
                        setTimeout(() => {
                            setQuery('');
                        }, 100);
                    }}>
                    {({ open }) => (
                        <>
                            {options.length > 0 && (
                                <Combobox.Input
                                    onFocus={() => !open}
                                    onBlur={event => {
                                        setTimeout(() => {
                                            setQuery('');
                                        }, 100);
                                    }}
                                    ref={inputRef}
                                    className="input-defaults input-defaults-blue"
                                    placeholder={label(
                                        'FormCaptureSelectEmpty'
                                    )}
                                    onChange={event =>
                                        setQuery(event.target.value)
                                    }
                                />
                            )}

                            {options.length > 0 ? (
                                realOptions.length > 0 ? (
                                    <Combobox.Options
                                        static
                                        style={{
                                            top: 'calc(100% + 5px)',
                                        }}
                                        className={cc([
                                            'pr-32 mt-8 space-y-16 text-blue-300 opacity-100 t-h6 bg-blue-20 top-full rounded-4 z-above transition-default max-h-[50vh] overflow-y-auto',
                                            {
                                                'opacity-100 pointer-events-auto': listWrapper,
                                                'opacity-0 pointer-events-none': !listWrapper,
                                            },
                                        ])}>
                                        {realOptions.map((option, index) => {
                                            const disabledOption =
                                                option.optionDisabled;
                                            return (
                                                <Combobox.Option
                                                    key={`${option.value}-${index}`}
                                                    value={option}
                                                    className=""
                                                    disabled={disabledOption}>
                                                    {({ selected }) => (
                                                        <button
                                                            key={option.value}
                                                            className={cc([
                                                                'flex items-center space-x-8 text-left outline-none focus:outline-none',
                                                                {
                                                                    'text-blue-100': value.includes(
                                                                        option.value
                                                                    ),
                                                                },
                                                            ])}>
                                                            {value.includes(
                                                                option.value
                                                            ) ? (
                                                                <FiCheckCircle className="pointer-events-none" />
                                                            ) : (
                                                                <FiCircle className="pointer-events-none" />
                                                            )}
                                                            <span className="mt-4 truncate max-w-[360px]">
                                                                {option.label}
                                                            </span>
                                                        </button>
                                                    )}
                                                </Combobox.Option>
                                            );
                                        })}
                                    </Combobox.Options>
                                ) : (
                                    <div
                                        className={cc([
                                            'input-defaults-select-option whitespace-nowrap',
                                        ])}>
                                        {label('FormCaptureSelectNothingFound')}
                                    </div>
                                )
                            ) : (
                                <div
                                    className={cc([
                                        'input-defaults-select-option whitespace-nowrap',
                                    ])}>
                                    {label('FormCaptureSelectNoTags')}
                                </div>
                            )}
                        </>
                    )}
                </Combobox>
            </div>
        </div>
    );
};

SearchFilterMultiselectComponent.propTypes = {
    name: t.string,
    options: t.arrayOf(
        t.shape({
            label: t.string,
            value: t.oneOfType([t.string, t.number, t.bool]),
        })
    ),
};

SearchFilterMultiselectComponent.defaultProps = {
    options: [],
};

export default SearchFilterMultiselectComponent;
