// React
import React, { useState, useEffect } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useForm } from 'react-hook-form';
import AnimateHeight from 'react-animate-height';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import Button from 'components/button';
import { FormFields, Nested } from 'components/_inputs';

const CreateNewComponent = ({
    fields,
    methods,
    forceOpen,
    forceClose,
    setValue,
    theme,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // STATE
    // ///////////////////

    const [toggled, setToggled] = useState(false);
    const [loading, setLoading] = useState(false);

    // ///////////////////
    // FORM
    // ///////////////////

    const form = useForm();

    // ///////////////////
    // METHODS
    // ///////////////////

    async function addItem(formData) {
        // Loading
        setLoading(true);
        try {
            // Run add method
            const item = await methods?.add.action(formData);

            // Loading
            setLoading(false);

            // Update value on parent component
            setValue(item.Id);
        } catch (error) {
            // Loading
            setLoading(false);
            console.warn(error);
        }
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        setToggled(forceOpen);
    }, [forceOpen]);

    useEffect(() => {
        if (forceClose) {
            setToggled(false);
        }
    }, [forceClose]);

    useEffect(() => {
        if (form.formState.isSubmitSuccessful) {
            form.reset(
                fields().reduce(
                    (acc, field) => ({
                        ...acc,
                        [field.name]: field.defaultValue || '',
                    }),
                    {}
                )
            );
        }
    }, [form.formState.isSubmitSuccessful]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="!mt-16">
            <AnimateHeight
                duration={300}
                animateOpacity={true}
                height={toggled ? 'auto' : 0}>
                <Nested theme={theme}>
                    <FormFields
                        {...{
                            fields: fields(),
                            form,
                            theme,
                        }}
                    />
                    <div className="flex items-center justify-end space-x-16">
                        <p
                            className={cc([
                                'hidden t-footnote text-coral-60 md:flex transition-default mr-auto',
                                {
                                    'opacity-0': !loading,
                                    'opacity-100': loading,
                                },
                            ])}>
                            {label('MessageSaved')}
                        </p>
                        <Button
                            variant="tertiary"
                            theme={theme}
                            action={() => {
                                form.reset();
                                setToggled(false);
                            }}
                            disabled={loading}>
                            {label('ButtonCancel')}
                        </Button>
                        <Button
                            variant="secondary"
                            theme={theme}
                            action={async () => {
                                await form.handleSubmit(
                                    async data => await addItem(data)
                                )();
                            }}
                            disabled={loading}>
                            {label('ButtonAdd')}
                        </Button>
                    </div>
                </Nested>
            </AnimateHeight>
            <AnimateHeight
                duration={300}
                animateOpacity={true}
                height={!toggled ? 'auto' : 0}>
                <Button
                    variant="secondary"
                    theme={theme}
                    action={() => setToggled(true)}>
                    {methods?.add?.label}
                </Button>
            </AnimateHeight>
        </div>
    );
};

CreateNewComponent.propTypes = {
    text: t.string,
};

CreateNewComponent.defaultProps = {
    setValue() {},
    text: '',
};

export default CreateNewComponent;
