// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useSignedUrl } from 'utilities/hooks';

// Components

const SignedUrlMedia = ({ url, type, className, title }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { signedUrl } = useSignedUrl(url);

    // ///////////////////
    // RETURN
    // ///////////////////

    return (
        <>
            {type === 'image' && (
                <img
                    src={signedUrl}
                    className={className}
                    alt={title}
                    title={title}
                />
            )}

            {type === 'video' && (
                <video
                    src={signedUrl}
                    className={className}
                    controls
                    alt={title}
                    title={title}
                />
            )}
        </>
    );
};

SignedUrlMedia.propTypes = {
    url: t.string.isRequired,
    type: t.oneOf(['image', 'video']).isRequired,
    className: t.string,
    title: t.string,
};

SignedUrlMedia.defaultProps = {
    type: 'image',
    className: '',
    title: '',
};

export default SignedUrlMedia;
