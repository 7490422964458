// React
import React, { useState } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useController } from 'react-hook-form';

// Utilities
import { useFormat, useLabels } from 'utilities/hooks';

const NumberComponent = ({
    controller,
    defaultValue,
    maxValue,
    minValue,
    name,
    object,
    placeholder,
    setFocus,
    theme,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const format = useFormat();
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
        defaultValue,
        rules: {
            validate: {
                required: v => (required ? !!v && !isNaN(v) : true),
                isNumber: v => (v ? /^[+-]?([0-9]*[.])?[0-9]+$/.test(v) : true),
            },
            min: minValue,
            max: maxValue,
        },
    });

    // ///////////////////
    // STATE
    // ///////////////////

    const [utilityValue, setUtilityValue] = useState(null);
    const [showRealInput, setShowRealInput] = useState(false);

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isBlueAlt = theme === 'blue-alt';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <label className="flex flex-col flex-grow">
            {inputLabel && (
                <span
                    className={cc([
                        'input-label',
                        {
                            'input-label-blue': isBlue,
                            'input-label-blue-alt': isBlueAlt,
                            'input-label-teal': isTeal,
                        },
                    ])}>
                    {inputLabel}
                    {required && <span>({label('FormCaptureRequired')})</span>}
                </span>
            )}
            {subLabel && (
                <span
                    className={cc([
                        'mt-8 input-sublabel',
                        {
                            'input-sublabel-blue': isBlue,
                            'input-sublabel-blue-alt': isBlueAlt,
                            'input-sublabel-teal': isTeal,
                        },
                    ])}>
                    {subLabel}
                </span>
            )}
            {!showRealInput && (
                <input
                    type="text"
                    readOnly
                    value={format.number(value)}
                    placeholder={
                        placeholder || label('FormCaptureTextEntryEmpty')
                    }
                    onFocus={() => {
                        setShowRealInput(true);
                        setTimeout(() => {
                            setFocus(name, { shouldSelect: true });
                        }, 20);
                    }}
                    className={cc([
                        'input-defaults appearance-none text-right',
                        {
                            'input-defaults-blue': isBlue,
                            'input-defaults-blue-alt': isBlueAlt,
                            'input-defaults-teal': isTeal,
                            'input-defaults-error': error,
                            'mt-16': label,
                        },
                    ])}
                />
            )}
            {showRealInput && (
                <input
                    ref={ref}
                    type="text"
                    defaultValue={value}
                    placeholder={placeholder || 0}
                    inputMode="decimal"
                    onChange={event => {
                        // Local value state
                        if (event.target.value.length > 0) {
                            onChange(
                                parseFloat(event.target.value.replace(/,/g, ''))
                            );

                            // UtilityValue
                            setUtilityValue(format.number(event.target.value));
                        } else {
                            onChange(0);
                            // UtilityValue
                            setUtilityValue(0);
                        }
                    }}
                    onBlur={() => {
                        // UtilityValue
                        setUtilityValue(null);

                        setShowRealInput(false);
                    }}
                    onFocus={() => {
                        // UtilityValue
                        setUtilityValue(format.number(value));
                    }}
                    className={cc([
                        'input-defaults appearance-none text-right',
                        {
                            'input-defaults-blue': isBlue,
                            'input-defaults-teal': isTeal,
                            'input-defaults-blue-alt': isBlueAlt,
                            'input-defaults-error': error,
                            'mt-16': label,
                        },
                    ])}
                />
            )}

            <div className="flex mt-6 -mb-16 input-utility-text">
                {error && (
                    <div className="input-utility-text-error">{errorLabel}</div>
                )}
                <div
                    className={cc([
                        'ml-auto text-right',
                        {
                            'input-utility-text-blue': isBlue,
                            'input-utility-text-blue-alt': isBlueAlt,
                            'input-utility-text-teal': isTeal,
                        },
                    ])}>
                    {utilityValue ? utilityValue : '-'}
                </div>
            </div>
        </label>
    );
};

NumberComponent.propTypes = {
    controller: t.object.isRequired,
    defaultValue: t.string,
    maxValue: t.number,
    minValue: t.number,
    name: t.string.isRequired,
    object: t.object.isRequired,
    placeholder: t.string,
    setFocus: t.func.isRequired,
    theme: t.oneOf(['teal', 'blue', 'blue-alt']),
};

NumberComponent.defaultProps = {
    minValue: 0,
    maxValue: null,
    theme: 'teal',
    setFocus() {},
};

export default NumberComponent;
