// React
import React from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';

// Utilities
import { useFormat } from 'utilities/hooks';

// Components

const DisplayFocusedValueComponent = ({ item, label, value, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const format = useFormat();

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            className={cc([
                'flex items-center justify-between rounded-8 border-4 py-16 px-20',
                {
                    'border-teal-20': isTeal,
                    'border-blue-20': isBlue,
                },
            ])}>
            <span
                className={cc([
                    't-h6',
                    {
                        'text-teal-100': isTeal,
                        'text-blue-100': isBlue,
                    },
                ])}>
                {label(item)}
            </span>
            <span
                className={cc([
                    't-h5',
                    {
                        'text-teal-60': isTeal,
                        'text-blue-60': isBlue,
                    },
                ])}>
                {format.number(value(item))}
            </span>
        </div>
    );
};

DisplayFocusedValueComponent.propTypes = {
    theme: t.oneOf(['teal', 'blue']),
};

DisplayFocusedValueComponent.defaultProps = {
    theme: 'teal',
};

export default DisplayFocusedValueComponent;
