// React
import React from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useController } from 'react-hook-form';

// Utilities
import { useLabels } from 'utilities/hooks';
import { s3 } from 'utilities/api';

// Components
import Button from 'components/button';

const ImageComponent = ({ controller, defaultValue, name, object, theme }) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
    });

    // ///////////////////
    // METHODS
    // ///////////////////

    async function uploadFile(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const uploadData = await s3.uploadMediaFile(file, () => {});
            onChange(uploadData.Location);
        }
    }

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <label className="flex flex-col" htmlFor={name}>
            {inputLabel && (
                <span
                    className={cc([
                        'input-label',
                        {
                            'input-label-blue': isBlue,
                            'input-label-teal': isTeal,
                        },
                    ])}>
                    {inputLabel}
                    {required && <span>({label('FormCaptureRequired')})</span>}
                </span>
            )}
            {subLabel && (
                <span
                    className={cc([
                        'mt-8 input-sublabel',
                        {
                            'input-sublabel-blue': isBlue,
                            'input-sublabel-teal': isTeal,
                        },
                    ])}>
                    {subLabel}
                </span>
            )}

            <Button
                variant="quaternary"
                theme={theme}
                className="self-start mt-16"
                action={'fake'}>
                {label('ButtonUploadImage')}
            </Button>
            <input
                ref={ref}
                id={name}
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={uploadFile}
                defaultValue={''}
                className={cc([
                    'input-defaults hidden',
                    {
                        'input-defaults-blue': isBlue,
                        'input-defaults-teal': isTeal,
                        'input-defaults-error': error,
                        'mt-16': inputLabel,
                    },
                ])}
            />
            {(value || defaultValue) && (
                <img
                    className="mt-24 rounded-4 w-128 h-128"
                    src={value || defaultValue}
                />
            )}

            {error && (
                <div className="flex mt-6 -mb-16 input-utility-text">
                    {error && (
                        <div className="input-utility-text-error">
                            {errorLabel}
                        </div>
                    )}
                </div>
            )}
        </label>
    );
};

ImageComponent.propTypes = {
    controller: t.object.isRequired,
    defaultValue: t.string,
    name: t.string.isRequired,
    object: t.object.isRequired,
    theme: t.oneOf(['teal', 'blue']),
};

ImageComponent.defaultProps = {
    theme: 'teal',
};

export default ImageComponent;
