// React
import React, { useEffect } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { Switch } from '@headlessui/react';
import { useController } from 'react-hook-form';
import { FormFields } from 'components/_inputs';
import AnimateHeight from 'react-animate-height';

// Utilities

const ToggleComponent = ({
    border,
    controller,
    defaultValue,
    form,
    name,
    nested,
    object,
    setValue,
    theme,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // HOOKS
    // ///////////////////

    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
        defaultValue,
    });

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
        }
    }, [defaultValue]);

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isBlueAlt = theme === 'blue-alt';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            className={cc([
                'flex items-center',
                {
                    'border-blue-20': isBlue,
                    'border-blue-20': isBlueAlt,
                    'border-teal-20': isTeal,
                    'border-4 rounded-8 p-24': border,
                },
            ])}
            ref={ref}>
            <div className="flex flex-col w-full">
                <div className="flex items-center justify-between space-x-24">
                    {(inputLabel || subLabel) && (
                        <div className="flex flex-col space-y-12">
                            {inputLabel && (
                                <span
                                    className={cc([
                                        'input-label',
                                        {
                                            'input-label-blue': isBlue,
                                            'input-label-blue-alt': isBlueAlt,
                                            'input-label-teal': isTeal,
                                        },
                                    ])}>
                                    {inputLabel}
                                </span>
                            )}
                            {subLabel && (
                                <span
                                    className={cc([
                                        'mt-8 input-sublabel',
                                        {
                                            'input-sublabel-blue': isBlue,
                                            'input-sublabel-blue-alt': isBlueAlt,
                                            'input-sublabel-teal': isTeal,
                                        },
                                    ])}>
                                    {subLabel}
                                </span>
                            )}
                        </div>
                    )}

                    <Switch
                        checked={value}
                        onChange={event => onChange(event)}
                        className={cc([
                            'input-defaults',
                            'flex !rounded-full !p-4 w-56 !h-32 shrink-0 transition-default',
                            {
                                'input-defaults-blue': isBlue,
                                'input-defaults-blue-alt': isBlueAlt,
                                'input-defaults-teal': isTeal,
                                'input-defaults-error': error,
                                '!bg-teal-10': isTeal && !value,
                                '!bg-teal-20': isTeal && value,
                                '!bg-blue-10': isBlue && !value,
                                '!bg-blue-20': isBlue && value,
                            },
                        ])}>
                        <span
                            aria-hidden="true"
                            className={cc([
                                'pointer-events-none inline-block h-24 w-24 rounded-full ring-0 transition-default',
                                {
                                    'translate-x-24': value,
                                    'translate-x-0': !value,
                                    'bg-teal-40': isTeal && !value,
                                    'bg-teal-60': isTeal && value,
                                    'bg-blue-40': isBlue && !value,
                                    'bg-blue-60': isBlue && value,
                                    'bg-blue-40': isBlueAlt && !value,
                                    'bg-blue-60': isBlueAlt && value,
                                },
                            ])}
                        />
                    </Switch>
                </div>
                {nested && nested.fields().length > 0 && (
                    <AnimateHeight
                        duration={300}
                        animateOpacity={true}
                        height={value ? 'auto' : 0}>
                        <div className="flex flex-col mt-32 space-y-24">
                            <FormFields
                                {...{
                                    fields: nested.fields(),
                                    form,
                                    theme,
                                }}
                            />
                        </div>
                    </AnimateHeight>
                )}
            </div>
        </div>
    );
};

ToggleComponent.propTypes = {
    border: t.bool,
    controller: t.object.isRequired,
    defaultValue: t.bool,
    form: t.object,
    name: t.string.isRequired,
    nested: t.object,
    object: t.object.isRequired,
    setValue: t.func,
    theme: t.oneOf(['teal', 'blue', 'blue-alt']),
};

ToggleComponent.defaultProps = {
    border: true,
    setValue() {},
    theme: 'teal',
};

export default ToggleComponent;
