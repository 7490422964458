// React
import React from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useController } from 'react-hook-form';

// Utilities
import { useLabels } from 'utilities/hooks';
import { useInitiativeDataStore } from 'utilities/store';

const Metrics = ({
    controller,
    object,
    metrics,
    name,
    theme,
    defaultValue,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // STORES
    // ///////////////////

    const { CONSTANTS } = useInitiativeDataStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label, getValueLabel } = useLabels();
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
        defaultValue,
        rules: {
            validate: {
                isNumber: v =>
                    Object.values(v).every(x =>
                        /^[+-]?([0-9]*[.])?[0-9]+$/.test(x)
                    ),
                required: v =>
                    required ? Object.values(v).every(x => !!x) : true,
            },
        },
    });

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div className="flex flex-col">
            {inputLabel && (
                <span
                    className={cc([
                        'input-label',
                        {
                            'input-label-blue': isBlue,
                            'input-label-teal': isTeal,
                        },
                    ])}>
                    {inputLabel}
                    {required && <span>({label('FormCaptureRequired')})</span>}
                </span>
            )}
            {subLabel && (
                <span
                    className={cc([
                        'mt-8 input-sublabel',
                        {
                            'input-sublabel-blue': isBlue,
                            'input-sublabel-teal': isTeal,
                        },
                    ])}>
                    {subLabel}
                </span>
            )}

            {/* Metrics */}
            <label className="flex flex-col mt-16 space-y-12" ref={ref}>
                {metrics.length > 0 &&
                    metrics.map((metric, index) => {
                        let postTitle;

                        // Switch to do operations for each metric type
                        switch (metric?.Type__c) {
                            case CONSTANTS.ACTIVITY_SUCCESS_METRICS.FINANCIAL:
                                // Set postTitle
                                postTitle = metric?.CurrencyIsoCode;
                                break;
                            case CONSTANTS.ACTIVITY_SUCCESS_METRICS.PEOPLE:
                                // Set postTitle
                                const gender = `${getValueLabel(
                                    'Initiative_Activity_Success_Metric__c.Gender__c',
                                    metric?.Gender__c
                                )}${
                                    metric?.Gender_Other__c
                                        ? ` (${metric?.Gender_Other__c})`
                                        : ''
                                }`;

                                const showAge =
                                    metric?.Highest_Age__c &&
                                    metric?.Highest_Age__c > 0;

                                postTitle = showAge
                                    ? `${gender} ${label(
                                          'SentenceBetweenAge'
                                      )} ${metric?.Lowest_Age__c} ${label(
                                          'SentenceAnd'
                                      )} ${metric?.Highest_Age__c}`
                                    : gender;

                                break;
                            default:
                                // Set postTitle
                                postTitle = null;
                                break;
                        }

                        return (
                            <div
                                key={index}
                                className={cc([
                                    'flex flex-col p-16 rounded-8',
                                    {
                                        'bg-blue-20': isBlue,
                                        'bg-teal-20': isTeal,
                                    },
                                ])}>
                                {/* Metric value */}
                                <div className="grid items-center grid-cols-8 gap-16">
                                    <div className="flex flex-col justify-center col-span-4">
                                        {/* Pre title */}
                                        <span
                                            className={cc([
                                                't-sh6',
                                                {
                                                    'text-blue-60': isBlue,
                                                    'text-teal-60': isTeal,
                                                },
                                            ])}>
                                            {getValueLabel(
                                                'Initiative_Activity_Success_Metric__c.Type__c',
                                                metric?.Type__c
                                            )}
                                        </span>
                                        {/* Title */}
                                        <h5
                                            className={cc([
                                                'truncate t-h6',
                                                {
                                                    'text-blue-100': isBlue,
                                                    'text-teal-100': isTeal,
                                                },
                                            ])}>
                                            {metric?.Name}
                                        </h5>
                                        {/* Post title */}
                                        {postTitle && (
                                            <span
                                                className={cc([
                                                    't-footnote',
                                                    {
                                                        'text-blue-60': isBlue,
                                                        'text-teal-60': isTeal,
                                                    },
                                                ])}>
                                                {postTitle}
                                            </span>
                                        )}
                                    </div>

                                    {/* Item controls */}
                                    <div className="flex justify-end col-span-4">
                                        <input
                                            style={{ direction: 'rtl' }}
                                            type="number"
                                            value={value?.[metric.Id] ?? ''}
                                            placeholder={0}
                                            onChange={event => {
                                                if (
                                                    event.target.value.length >
                                                    0
                                                ) {
                                                    const newValue = event.target.value.replace(
                                                        /,/g,
                                                        '.'
                                                    );
                                                    onChange({
                                                        ...value,
                                                        [metric.Id]: parseFloat(
                                                            newValue
                                                        ),
                                                    });
                                                } else {
                                                    onChange({
                                                        ...value,
                                                        [metric.Id]: null,
                                                    });
                                                }
                                            }}
                                            onBlur={event => {
                                                if (
                                                    event.target.value.length >
                                                    0
                                                ) {
                                                    const newValue = event.target.value.replace(
                                                        /,/g,
                                                        '.'
                                                    );
                                                    onChange({
                                                        ...value,
                                                        [metric.Id]: parseFloat(
                                                            parseFloat(
                                                                newValue
                                                            ).toFixed(2)
                                                        ),
                                                    });
                                                }
                                            }}
                                            className={cc([
                                                'text-right',
                                                'input-defaults appearance-none',
                                                {
                                                    'input-defaults-blue': isBlue,
                                                    'input-defaults-teal': isTeal,
                                                    'input-defaults-error': error,
                                                },
                                            ])}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </label>

            {error && (
                <div className="flex mt-6 -mb-16 input-utility-text">
                    {error && (
                        <div className="input-utility-text-error">
                            {errorLabel}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

Metrics.propTypes = {
    controller: t.object,
    defaultValue: t.object,
    disabled: t.bool,
    object: t.object,
    metrics: t.array.isRequired,
    name: t.string.isRequired,
    placeholder: t.string,
    theme: t.oneOf(['teal', 'blue']),
};

Metrics.defaultProps = {
    controller: null,
    defaultValue: null,
    disabled: false,
    metrics: [],
    name: '',
    placeholder: '',
    theme: 'teal',
};

export default Metrics;
