// React
import React from 'react';

// Packages

// Utilities

// Components
import EmptyState from 'components/_wizard/emptyState';

const ErrorCardComponent = ({ error }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return <EmptyState {...{ text: error }} />;
};

ErrorCardComponent.propTypes = {};

ErrorCardComponent.defaultProps = {};

export default ErrorCardComponent;
