// React
import React, { useEffect, useState, useRef } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useFormContext, useController } from 'react-hook-form';
import { Combobox } from '@headlessui/react';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import EmptyState from './../emptyState';
import CreateNew from './../createNew';

// Icons
import { FiChevronDown, FiCheck } from 'react-icons/fi';

const SelectComponent = ({
    className,
    controller,
    create,
    defaultValue,
    disabled,
    missingOptionsLabel,
    name,
    object,
    options,
    placeholder,
    setValue,
    sort,
    theme,
    unregisterInput,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
        defaultValue,
        rules: { required },
    });
    const inputRef = useRef();

    const formContext = useFormContext();

    // ///////////////////
    // STATE
    // ///////////////////

    const [asyncOptionsFetched, setAsyncOptionsFetched] = useState(false);
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [loadedOptions, setLoadedOptions] = useState([]);
    const [query, setQuery] = useState('');
    const [active, setActive] = useState(false);

    // ///////////////////
    // METHODS
    // ///////////////////

    function getPlaceholder() {
        if (loadingOptions) {
            return label('FormCaptureSelectLoadingOptions');
        } else {
            if (loadedOptions.length > 0) {
                return placeholder || label('FormCaptureSelectEmpty');
            } else {
                return label('FormCaptureSelectNoOptions');
            }
        }
    }

    // ///////////////////
    // DATA
    // ///////////////////

    const missingOptions = missingOptionsLabel && loadedOptions.length === 0;

    const realOptions = loadedOptions
        .sort((a, b) => (sort ? a?.label?.localeCompare(b?.label) : true))
        .filter(option => !option.disabledInSelect)
        .filter(option =>
            query === ''
                ? true
                : option.label
                      .toLowerCase()
                      .replace(/\s+/g, '')
                      .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

    const realValue =
        value || defaultValue
            ? loadedOptions.find(option => option.value === value) ||
              loadedOptions.find(option => option.value === defaultValue)
            : null;

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        // Assume normal options
        if (Array.isArray(options)) {
            setLoadedOptions(options);
        }
        // Or perhaps async options
        else {
            async function getOptions() {
                setLoadingOptions(true);
                setLoadedOptions(await options());
                setLoadingOptions(false);
                setAsyncOptionsFetched(true);
            }
            if (!asyncOptionsFetched) {
                getOptions();
            }
        }
    }, [options]);

    // Defaultvalue
    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
        }
    }, [defaultValue, loadedOptions]);

    // Update value correctly when using setValue
    useEffect(() => {
        if (value && value.length > 0) {
            setValue(name, value);
        }
    }, [loadedOptions]);

    useEffect(() => {
        if (formContext && unregisterInput) {
            formContext.unregister(name);
        }
    }, [unregisterInput]);

    useEffect(() => {
        if (inputRef?.current && !active) {
            inputRef?.current?.blur();
        }
        if (active) {
            inputRef?.current?.focus();
            inputRef?.current?.select();
        }
    }, [active]);

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isBlueAlt = theme === 'blue-alt';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return !unregisterInput ? (
        <>
            <label className="flex flex-col grow">
                {inputLabel && (
                    <span
                        className={cc([
                            'input-label',
                            {
                                'input-label-blue': isBlue,
                                'input-label-blue-alt': isBlueAlt,
                                'input-label-teal': isTeal,
                            },
                        ])}>
                        {inputLabel}
                        {required && (
                            <span>({label('FormCaptureRequired')})</span>
                        )}
                    </span>
                )}
                {subLabel && (
                    <span
                        className={cc([
                            'mt-8 input-sublabel',
                            {
                                'input-sublabel-blue': isBlue,
                                'input-sublabel-blue-alt': isBlueAlt,
                                'input-sublabel-teal': isTeal,
                            },
                        ])}>
                        {subLabel}
                    </span>
                )}
                {missingOptions && (
                    <div className="mt-16">
                        <EmptyState label={missingOptionsLabel} theme={theme} />
                    </div>
                )}

                {!missingOptions && (
                    <div
                        className={cc([
                            'flex flex-col',
                            {
                                'mt-8': inputLabel || subLabel,
                            },
                        ])}>
                        <div className={cc(['relative flex', className])}>
                            <Combobox
                                disabled={disabled}
                                value={realValue}
                                onChange={event => {
                                    onChange(event.value);
                                    setTimeout(() => {
                                        setActive(false);
                                        setQuery('');
                                    }, 100);
                                }}>
                                {({ open }) => (
                                    <>
                                        {loadedOptions.length === 0 ? (
                                            <input
                                                className={cc([
                                                    'input-defaults',
                                                    'appearance-none grow !pr-[32px] max-w-full',
                                                    {
                                                        'input-defaults-blue': isBlue,
                                                        'input-defaults-blue-alt': isBlueAlt,
                                                        'input-defaults-teal': isTeal,
                                                        'input-defaults-error': error,
                                                    },
                                                ])}
                                                placeholder={getPlaceholder()}
                                                disabled
                                            />
                                        ) : (
                                            <Combobox.Input
                                                onFocus={() => {
                                                    !open && setActive(true);
                                                }}
                                                onBlur={event => {
                                                    setTimeout(() => {
                                                        setActive(false);
                                                        setQuery('');
                                                    }, 100);
                                                }}
                                                ref={inputRef}
                                                className={cc([
                                                    'input-defaults',
                                                    'appearance-none grow !pr-[32px] max-w-full',
                                                    {
                                                        'input-defaults-blue': isBlue,
                                                        'input-defaults-blue-alt': isBlueAlt,
                                                        'input-defaults-teal': isTeal,
                                                        'input-defaults-error': error,
                                                    },
                                                ])}
                                                displayValue={item =>
                                                    item ? item.label : null
                                                }
                                                placeholder={getPlaceholder()}
                                                onChange={event =>
                                                    setQuery(event.target.value)
                                                }
                                                disabled={
                                                    disabled ||
                                                    loadedOptions.length === 0
                                                }
                                            />
                                        )}
                                        {loadedOptions.length > 0 && (
                                            <>
                                                <Combobox.Button
                                                    onClick={() =>
                                                        open || active
                                                            ? setActive(false)
                                                            : setActive(true)
                                                    }
                                                    className="absolute inset-y-0 right-0 flex items-center justify-center w-32">
                                                    <FiChevronDown className="stroke-current" />
                                                </Combobox.Button>
                                                {(open || active) && (
                                                    <Combobox.Options
                                                        static
                                                        style={{
                                                            top:
                                                                'calc(100% + 5px)',
                                                        }}
                                                        className={cc([
                                                            'input-defaults-select-options',
                                                            {
                                                                'input-defaults-select-options-blue': isBlue,
                                                                'input-defaults-select-options-blue-alt': isBlueAlt,
                                                                'input-defaults-select-options-teal': isTeal,
                                                            },
                                                        ])}>
                                                        {realOptions.length >
                                                        0 ? (
                                                            realOptions.map(
                                                                (
                                                                    option,
                                                                    index
                                                                ) => {
                                                                    const disabledOption =
                                                                        option.optionDisabled;
                                                                    return (
                                                                        <Combobox.Option
                                                                            key={`${option.value}-${index}`}
                                                                            onMouseDown={event => {
                                                                                event.target.click();
                                                                            }}
                                                                            className={({
                                                                                active,
                                                                                selected,
                                                                            }) =>
                                                                                cc(
                                                                                    [
                                                                                        'input-defaults-select-option',
                                                                                        {
                                                                                            'opacity-70': disabledOption,
                                                                                            'input-defaults-select-option-blue': isBlue,
                                                                                            'input-defaults-select-option-blue-alt': isBlueAlt,
                                                                                            'input-defaults-select-option-teal': isTeal,
                                                                                            'input-defaults-select-option-active-blue':
                                                                                                active &&
                                                                                                isBlue,
                                                                                            'input-defaults-select-option-active-blue-alt':
                                                                                                active &&
                                                                                                isBlueAlt,
                                                                                            'input-defaults-select-option-active-teal':
                                                                                                active &&
                                                                                                isTeal,
                                                                                            'input-defaults-select-option-selected': selected,
                                                                                        },
                                                                                    ]
                                                                                )
                                                                            }
                                                                            value={
                                                                                option
                                                                            }
                                                                            disabled={
                                                                                disabledOption
                                                                            }>
                                                                            {({
                                                                                selected,
                                                                            }) => (
                                                                                <div className="relative flex items-center">
                                                                                    {selected && (
                                                                                        <FiCheck className="absolute" />
                                                                                    )}
                                                                                    <span className="ml-24">
                                                                                        {
                                                                                            option.label
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            )}
                                                                        </Combobox.Option>
                                                                    );
                                                                }
                                                            )
                                                        ) : (
                                                            <div
                                                                className={cc([
                                                                    'input-defaults-select-option',
                                                                    {
                                                                        'input-defaults-select-option-blue': isBlue,
                                                                        'input-defaults-select-option-blue-alt': isBlueAlt,
                                                                        'input-defaults-select-option-teal': isTeal,
                                                                    },
                                                                ])}>
                                                                {label(
                                                                    'FormCaptureSelectNothingFound'
                                                                )}
                                                            </div>
                                                        )}
                                                    </Combobox.Options>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </Combobox>
                        </div>
                        {error && (
                            <div className="flex mt-6 -mb-16 input-utility-text">
                                {error && (
                                    <div className="input-utility-text-error">
                                        {errorLabel}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </label>
            {create?.methods?.add?.action && (
                <CreateNew
                    {...{
                        ...create,
                        forceOpen: loadedOptions.length === 0,
                        forceClose: value,
                        setValue(newItem) {
                            setValue(name, newItem);
                        },
                        theme,
                    }}
                />
            )}
        </>
    ) : null;
};

SelectComponent.propTypes = {
    className: t.string,
    controller: t.object.isRequired,
    create: t.shape({
        fields: t.func.isRequired,
        methods: t.shape({
            add: t.shape({
                label: t.string,
                action: t.func.isRequired,
            }),
        }),
    }),
    defaultValue: t.string,
    disabled: t.bool,
    missingOptionsLabel: t.string,
    name: t.string.isRequired,
    object: t.object.isRequired,
    options: t.oneOfType([
        t.arrayOf(
            t.shape({
                label: t.string,
                value: t.oneOfType([t.string, t.number, t.bool]),
            })
        ),
        t.func,
    ]),
    placeholder: t.string,
    setValue: t.func,
    sort: t.bool,
    theme: t.oneOf(['teal', 'blue', 'blue-alt']),
    unregisterInput: t.bool,
};

SelectComponent.defaultProps = {
    setValue() {},
    sort: true,
    theme: 'teal',
};

export default SelectComponent;
