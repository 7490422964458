// React
import React, { useEffect, useMemo } from 'react';

// Packages
import t from 'prop-types';
import { FormProvider, useFormState } from 'react-hook-form';

// Utilities

// Components
import {
    Text,
    Toggle,
    Select,
    SelectList,
    LongText,
    LongTextWysiwyg,
    DatePicker,
    DateRange,
    Metrics,
    Number,
    NumberFocused,
    Range,
    Image,
    Reflection,
    Section,
    Nested,
    TagsList,
    DisplayCard,
    DisplayFocusedValue,
    ErrorCard,
    File,
    SelectLongList,
} from 'components/_inputs';

const FormFields = ({ fields, form, theme: formTheme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { watch } = form;

    // ///////////////////
    // FORM
    // ///////////////////

    const { errors, isValid } = useFormState({ control: form.control });

    // ///////////////////
    // DATA
    // ///////////////////

    // Object to contain form field watchers
    const watchFields = useMemo(() => {
        return fields.reduce(
            (acc, field) => ({
                ...acc,
                ...(field.onWatch ? { [field.name]: field.onWatch } : {}),
            }),
            {}
        );
    }, [fields]);

    // ///////////////////
    // METHODS
    // ///////////////////

    const renderFormFields = fields => {
        const { control, setValue, setFocus } = form;
        return fields.map((field, index) => {
            // Extract all possible values
            const { type, name, theme: fieldTheme, ...rest } = field;

            const theme = fieldTheme ? fieldTheme : formTheme;

            const sharedProps = {
                name,
                theme,
                ...rest,
                setValue,
                setFocus,
                form,
                controller: control,
            };

            switch (type) {
                case 'Text':
                    return <Text key={name + index} {...sharedProps} />;
                case 'LongText':
                    return <LongText key={name + index} {...sharedProps} />;
                case 'LongTextWysiwyg':
                    return (
                        <LongTextWysiwyg key={name + index} {...sharedProps} />
                    );
                case 'Select':
                    return <Select key={name + index} {...sharedProps} />;
                case 'SelectList':
                    return <SelectList key={name + index} {...sharedProps} />;
                case 'SelectLongList':
                    return (
                        <SelectLongList key={name + index} {...sharedProps} />
                    );
                case 'TagsList':
                    return <TagsList key={name + index} {...sharedProps} />;
                case 'DateRange':
                    return <DateRange key={name + index} {...sharedProps} />;
                case 'DatePicker':
                    return <DatePicker key={name + index} {...sharedProps} />;
                case 'Metrics':
                    return <Metrics key={name + index} {...sharedProps} />;
                case 'Number':
                    return <Number key={name + index} {...sharedProps} />;
                case 'NumberFocused':
                    return (
                        <NumberFocused key={name + index} {...sharedProps} />
                    );
                case 'Toggle':
                    return <Toggle key={name + index} {...sharedProps} />;
                case 'Image':
                    return <Image key={name + index} {...sharedProps} />;
                case 'File':
                    return <File key={name + index} {...sharedProps} />;
                case 'Range':
                    return <Range key={name + index} {...sharedProps} />;
                case 'Reflection':
                    return <Reflection key={name + index} {...sharedProps} />;
                case 'Section':
                    return (
                        <Section
                            key={'section' + index}
                            {...{ ...sharedProps }}
                        />
                    );
                case 'Nested':
                    return (
                        <Nested key={name + index} theme={theme}>
                            {renderFormFields(field.fields)}
                        </Nested>
                    );
                case 'Empty':
                    return null;

                // DISPLAY ITEMS
                case 'DisplayCard':
                    return <DisplayCard key={name + index} {...sharedProps} />;
                case 'DisplayFocusedValue':
                    return (
                        <DisplayFocusedValue
                            key={name + index}
                            {...sharedProps}
                        />
                    );
                case 'ErrorCard':
                    return <ErrorCard key={name + index} {...sharedProps} />;
                default:
                    break;
            }
        });
    };

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        // Run onWatch from form field if it exists - all values being watched
        const subscription = watch((value, { name }) => {
            if (watchFields[name]) {
                watchFields[name](value[name], form);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    // Effects doesn't play well here
    if (!isValid && errors && Object.values(errors).length > 0) {
        const errorRef = Object.values(errors)[0]?.ref;
        if (errorRef && errorRef.scrollIntoView) {
            errorRef?.scrollIntoView && errorRef?.scrollIntoView();
        }
    }

    // ///////////////////
    // RENDER
    // ///////////////////

    return <FormProvider {...form}>{renderFormFields(fields)}</FormProvider>;
};

FormFields.propTypes = {
    fields: t.array,
    form: t.object,
    theme: t.oneOf(['blue', 'teal', 'blue-alt']),
};

FormFields.defaultProps = {
    fields: [],
    theme: 'teal',
};

export default FormFields;
