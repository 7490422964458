// React
import React, { useState } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import AnimateHeight from 'react-animate-height';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import CardContentSection from 'components/cardContentSection';
import Button from 'components/button';
import TagsAlt from 'components/tagsAlt';

// Icons
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const DisplayCardComponent = ({ item, items, editButton = null, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label, tagLabel } = useLabels();

    // ///////////////////
    // STATE
    // ///////////////////

    const [toggled, setToggled] = useState(false);

    // ///////////////////
    // DATA
    // ///////////////////

    const firstEntry = items?.[0];
    const restOfEntries = items.filter((x, index) => index !== 0);

    // ///////////////////
    // METHODS
    // ///////////////////

    function Entry({ entry, actions = null }) {
        switch (entry.type) {
            case 'audienceTags':
                const tags = entry?.tags(item);
                const multipleTags = tags.length > 1;
                return (
                    <CardContentSection
                        {...{
                            theme,
                            title: entry?.label(item),
                            actions,
                        }}>
                        {tags.map((t, index) => (
                            <p key={index}>
                                {tagLabel(t.tags[0])}
                                {multipleTags ? (
                                    <span className="ml-8 opacity-80">
                                        ({t.collection?.Account_Name__c})
                                    </span>
                                ) : null}
                            </p>
                        ))}
                    </CardContentSection>
                );
            case 'tags':
                return (
                    <CardContentSection
                        {...{
                            theme,
                            title: entry?.label(item),
                            actions,
                        }}>
                        <TagsAlt
                            {...{
                                tags: entry?.tags(item),
                                theme: 'teal',
                                variant: 'darkest',
                                className: 'mt-12',
                            }}
                        />
                    </CardContentSection>
                );
            default:
                const value = entry?.value(item);
                return (
                    <CardContentSection
                        {...{
                            theme,
                            title: entry?.label(item),
                            actions,
                        }}>
                        <p>{value ? value : '-'}</p>
                    </CardContentSection>
                );
        }
    }

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            className={cc([
                'flex flex-col space-y-12',
                {
                    'border-teal-20': isTeal,
                    'border-blue-20': isBlue,
                },
            ])}>
            {/* First item - always visible */}
            <Entry
                {...{
                    entry: firstEntry,
                    actions: (
                        <>
                            {editButton}
                            <Button
                                title={label('ButtonExpandCollapse')}
                                variant="tertiary"
                                theme={theme}
                                icon={toggled ? FiChevronUp : FiChevronDown}
                                iconPosition="center"
                                iconType="stroke"
                                className="!px-8"
                                action={() => setToggled(!toggled)}
                            />
                        </>
                    ),
                }}
            />

            {/* Rest of items */}
            <AnimateHeight
                duration={300}
                animateOpacity={true}
                height={toggled ? 'auto' : 0}>
                <div className="flex flex-col space-y-12">
                    {restOfEntries.map((x, index) => (
                        <Entry key={index} {...{ entry: x }} />
                    ))}
                </div>
            </AnimateHeight>
        </div>
    );
};

DisplayCardComponent.propTypes = {
    theme: t.oneOf(['teal', 'blue']),
};

DisplayCardComponent.defaultProps = {
    theme: 'teal',
};

export default DisplayCardComponent;
