// React
import React, { useEffect, useState } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useFormContext, useController } from 'react-hook-form';

// Utilities
import { useLabels } from 'utilities/hooks';

const TextComponent = ({
    controller,
    customValidation,
    customValidationError,
    defaultValue,
    disabled,
    display,
    maxLength,
    name,
    object,
    placeholder,
    setValue,
    theme,
    unregisterInput,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
        defaultValue: defaultValue,
        rules: {
            maxLength,
            required,
            validate: {
                customValidation: v =>
                    customValidation ? customValidation(v) : true,
            },
        },
    });

    const formContext = useFormContext();

    // ///////////////////
    // STATE
    // ///////////////////

    const [lengthValue, setLengthValue] = useState(0);

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (value) {
            setLengthValue(value.length);
        }
    }, []);

    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
            setLengthValue(defaultValue.length);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (formContext && unregisterInput) {
            formContext.unregister(name);
        }
    }, [unregisterInput]);

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isBlueAlt = theme === 'blue-alt';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return !unregisterInput ? (
        <label className="flex flex-col">
            {inputLabel && (
                <span
                    className={cc([
                        'input-label',
                        {
                            'input-label-blue': isBlue,
                            'input-label-blue-alt': isBlueAlt,
                            'input-label-teal': isTeal,
                        },
                    ])}>
                    {inputLabel}
                    {required && <span>({label('FormCaptureRequired')})</span>}
                </span>
            )}
            {subLabel && (
                <span
                    className={cc([
                        'mt-8 input-sublabel',
                        {
                            'input-sublabel-blue': isBlue,
                            'input-sublabel-blue-alt': isBlueAlt,
                            'input-sublabel-teal': isTeal,
                        },
                    ])}>
                    {subLabel}
                </span>
            )}
            {display ? (
                <div
                    className={cc([
                        'ml-8 mt-4 t-body',
                        {
                            'text-blue-60': isBlue,
                            'text-blue-60': isBlueAlt,
                            'text-teal-60': isTeal,
                        },
                    ])}
                    dangerouslySetInnerHTML={{ __html: value }}
                />
            ) : (
                <>
                    <input
                        ref={ref}
                        type="text"
                        disabled={disabled || display}
                        defaultValue={defaultValue}
                        maxLength={maxLength ? maxLength : 'none'}
                        placeholder={
                            placeholder || label('FormCaptureTextEntryEmpty')
                        }
                        onChange={event => {
                            const newValue = event.target.value;
                            // Local value state
                            setLengthValue(newValue.length);
                            onChange(newValue);
                        }}
                        className={cc([
                            'input-defaults',
                            {
                                'input-defaults-blue': isBlue,
                                'input-defaults-blue-alt': isBlueAlt,
                                'input-defaults-teal': isTeal,
                                'input-defaults-error': error,
                                'mt-16': inputLabel,
                            },
                        ])}
                    />

                    {(maxLength > 0 || customValidationError || error) && (
                        <div className="flex mt-6 -mb-16 input-utility-text">
                            {(error || customValidationError) && (
                                <div className="input-utility-text-error">
                                    {error?.type === 'customValidation' &&
                                        customValidationError}
                                    {error &&
                                        error?.type !== 'customValidation' &&
                                        errorLabel}
                                </div>
                            )}
                            {maxLength > 0 && (
                                <div
                                    className={cc([
                                        'ml-auto text-right',
                                        {
                                            'input-utility-text-blue': isBlue,
                                            'input-utility-text-blue-alt': isBlueAlt,
                                            'input-utility-text-teal': isTeal,
                                        },
                                    ])}>
                                    {lengthValue} / {maxLength.toString()}
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </label>
    ) : null;
};

TextComponent.propTypes = {
    controller: t.object.isRequired,
    customValidation: t.func,
    customValidationError: t.string,
    defaultValue: t.string,
    disabled: t.bool,
    display: t.bool,
    maxLength: t.number,
    name: t.string.isRequired,
    object: t.object.isRequired,
    placeholder: t.string,
    setValue: t.func,
    theme: t.oneOf(['teal', 'blue', 'blue-alt']),
    unregisterInput: t.bool,
};

TextComponent.defaultProps = {
    display: false,
    setValue() {},
    theme: 'teal',
};

export default TextComponent;
