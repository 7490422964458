// React
import React, { useState, useEffect } from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';
import { useFormContext, useController } from 'react-hook-form';

// Utilities
import { useFormat, useLabels } from 'utilities/hooks';

const NumberFocusedComponent = ({
    controller,
    defaultValue,
    maxValue,
    minValue,
    name,
    object,
    placeholder,
    setFocus,
    theme,
    unregisterInput,
}) => {
    // ///////////////////
    // OBJECT CONFIG
    // ///////////////////

    const { label: inputLabel, subLabel, errorLabel, required } = object || {};

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const format = useFormat();
    const {
        field: { onChange, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control: controller,
        defaultValue,
        rules: {
            validate: {
                required: v =>
                    required ? (v === 0 || !!v) && !isNaN(v) : true,
                isNumber: v => (v ? /^[+-]?([0-9]*[.])?[0-9]+$/.test(v) : true),
            },
            min: minValue,
            max: maxValue,
        },
    });

    const formContext = useFormContext();

    // ///////////////////
    // STATE
    // ///////////////////

    const [utilityValue, setUtilityValue] = useState(null);
    const [showRealInput, setShowRealInput] = useState(false);

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (formContext && unregisterInput) {
            formContext.unregister(name);
        }
    }, [unregisterInput]);

    // ///////////////////
    // THEMING
    // ///////////////////

    const isBlue = theme === 'blue';
    const isTeal = theme === 'teal';

    // ///////////////////
    // RENDER
    // ///////////////////

    return !unregisterInput ? (
        <label
            className={cc([
                'flex flex-col p-24 rounded-8',
                {
                    'bg-blue-20': isBlue,
                    'bg-teal-20': isTeal,
                },
            ])}>
            <div className="grid items-center justify-between grid-cols-6 gap-16 space-x-16">
                {/* <div className="flex items-center justify-between space-x-16"> */}
                <div className="flex flex-col col-span-4">
                    {inputLabel && (
                        <span
                            className={cc([
                                'input-label',
                                {
                                    'input-label-blue': isBlue,
                                    'input-label-teal': isTeal,
                                },
                            ])}>
                            {inputLabel}
                            {required && (
                                <span>({label('FormCaptureRequired')})</span>
                            )}
                        </span>
                    )}
                    {subLabel && (
                        <span
                            className={cc([
                                'input-sublabel',
                                {
                                    'input-sublabel-blue': isBlue,
                                    'input-sublabel-teal': isTeal,
                                },
                            ])}>
                            {subLabel}
                        </span>
                    )}
                </div>
                <div className="col-span-2">
                    {!showRealInput && (
                        <input
                            type="text"
                            readOnly
                            value={
                                value || value === 0 ? format.number(value) : ''
                            }
                            placeholder={
                                placeholder ||
                                label('FormCaptureTextEntryEmpty')
                            }
                            onFocus={() => {
                                setShowRealInput(true);
                                setTimeout(() => {
                                    setFocus(name, { shouldSelect: true });
                                }, 20);
                            }}
                            className={cc([
                                'input-defaults appearance-none text-right w-full',
                                {
                                    'input-defaults-blue': isBlue,
                                    'input-defaults-teal': isTeal,
                                    'input-defaults-error': error,
                                },
                            ])}
                        />
                    )}
                    {showRealInput && (
                        <input
                            ref={ref}
                            type="text"
                            defaultValue={value || value === 0 ? value : null}
                            placeholder={placeholder}
                            inputMode="decimal"
                            onChange={event => {
                                // Local value state
                                if (event.target.value.length > 0) {
                                    onChange(
                                        parseFloat(
                                            event.target.value.replace(/,/g, '')
                                        )
                                    );

                                    // UtilityValue
                                    setUtilityValue(
                                        format.number(event.target.value)
                                    );
                                } else {
                                    onChange(0);
                                    // UtilityValue
                                    setUtilityValue(0);
                                }
                            }}
                            onBlur={() => {
                                // UtilityValue
                                setUtilityValue(null);

                                setShowRealInput(false);
                            }}
                            onFocus={() => {
                                // UtilityValue
                                setUtilityValue(format.number(value));
                            }}
                            className={cc([
                                'input-defaults appearance-none text-right w-full',
                                {
                                    'input-defaults-blue': isBlue,
                                    'input-defaults-teal': isTeal,
                                    'input-defaults-error': error,
                                },
                            ])}
                        />
                    )}
                </div>
            </div>

            <div className="flex mt-6 -mb-16 input-utility-text">
                {error && (
                    <div className="input-utility-text-error">{errorLabel}</div>
                )}
                <div
                    className={cc([
                        'ml-auto text-right',
                        {
                            'input-utility-text-blue': isBlue,
                            'input-utility-text-teal': isTeal,
                        },
                    ])}>
                    {utilityValue ? utilityValue : '-'}
                </div>
            </div>
        </label>
    ) : null;
};

NumberFocusedComponent.propTypes = {
    controller: t.object.isRequired,
    defaultValue: t.string,
    maxValue: t.number,
    minValue: t.number,
    name: t.string.isRequired,
    object: t.object.isRequired,
    placeholder: t.string,
    setFocus: t.func.isRequired,
    theme: t.oneOf(['teal', 'blue']),
};

NumberFocusedComponent.defaultProps = {
    minValue: 0,
    maxValue: null,
    theme: 'teal',
    setFocus() {},
};

export default NumberFocusedComponent;
